const localURL = "https://trinijunglejuice.test";
// const localURL = "https://tjj-backend.test";
// const serverURL = localURL;
const stagingURL = "https://staging.trinijunglejuice.com";
const serverURL = stagingURL;
const baseURL = serverURL + "/api";

export const PER_PAGE_COUNT = {
  FEATURED_VIDEOS_HOME: 10,
  NEW_VIDEOS_HOME: 8,
  POPULAR_VIDEOS_HOME: 8,
  TOP_TRENDING_VIDEOS_HOME: 10,
  ALL_VIDEOS: 12,
  UPCOMING_CARNIVALS: 8,
  FEATURED_CARNIVALS: 9,
  CARNIVAL_NEWS: 6,
  PAST_CARNIVALS: 4,
  CARNIVALS_BY_LOCATION: 6,
  CARNIVAL_EVENTS: 20,
  CARNIVAL_GALLERIES: 8,
  CARNIVAL_GALLERIES_BY_LOCATION: 6,
  CARNIVAL_VIDEOS: 6,
  RECENT_CARNIVALS: 6,
  TJJ_HOME_VIDEOS: 6,
  TJJ_HOME_NEWS: 4,
  CARNIVAL_MUSIC: 8,
  CARNIVAL_ARTICLES: 6,
  MUSIC_NEW_RELEASES: 8,
  MUSIC_ARTISTS: 10,
  MUSIC_ARTISTS_MORE: 15,
  MORE_ARTISTS: 5,
  MORE_ARTISTS_ALBUM: 4,
  ALL_ALBUMS: 9,
  ALL_DATA: 10000,
  MINI_COUNTRY: 100,
  CARNIVAL_CLASSIFIEDS: 8,
  ARTIST_ALBUM_SECTION: 6,
  ARTIST_VIDEO_SECTION: 6,
  COMMENTS_PER_PAGE: 5,
  REVIEWS_PER_PAGE: 2,
  FEATURED_EVENTS: 10000,
  EVENTS: 8,
  ALL_EVENTS: 20,
  EVENT_CATEGORIES_PROMOTERS: 50,
  EVENT_ALL_CATEGORIES_PROMOTERS: 10000,
  EVENT_VIDEOS: 5,
  RELATED_EVENT_GALLERIES: 8,
  GALLERIES_HOME: 8,
  ALL_GALLERIES: 9,
  MOST_LIKED_PHOTOS: 25,
  PHOTOS_ITEMS_COUNT: 50,
  COMMENTS_ITEMS_COUNT: 10,
  MAX_PHOTOS_DISPLAY_COUNT: 500,
  MAX_COMMENTS_DISPLAY_COUNT: 50,
  NEWSARTICLES_ITEM_COUNT: 10,
  ALL_CARNIVALS: 12,
  GET_INBOX: 12,
  GET_GALLERY_REVIEWS: 6,
  GET_NOTIFICATIONS: 10,
  REVIEWS_ITEM_COUNT:4,
};

export const SERVER_URL = {
  REGISTER: baseURL + "/register",
  LOGIN: baseURL + "/login",
  SOCIAL_LOGIN: baseURL + "/social/login",
  GET_DETAILS_SECTION: baseURL + "/carnivals/",
  GET_DETAILS_SECTION_BY_SLUG: baseURL + "/carnivals/slug",
  GET_ALL_VIDEOS: baseURL + "/videos",
  GET_UPCOMING_CARNIVALS: baseURL + "/carnivals/upcoming",
  GET_FEATURED_CARNIVALS: baseURL + "/carnivals/featured",
  GET_CARNIVAL_NEWS: baseURL + "/carnivals/reviews",
  GET_PAST_CARNIVALS: baseURL + "/carnivals/past",
  GET_CARNIVALS_BY_LOCATION: baseURL + "/carnivals",
  GET_CARNIVAL_EVENTS: baseURL + "/carnivals/events",
  GET_CARNIVAL_GALLERIES: baseURL + "/carnivals/galleries",
  GET_CARNIVAL_GALLERIES_BY_LOCATION: baseURL + "/carnivals/galleries",
  GET_RECENT_CARNIVALS: baseURL + "/carnivals/past",
  GET_CARNIVAL_VIDEOS: baseURL + "/carnivals/videos",
  GET_CARNIVAL_MUSIC: baseURL + "/carnivals/music",
  GET_CARNIVAL_ARTICLES: baseURL + "/news-articles",
  GET_WORLD_CARNIVALS: baseURL + "/carnivals/world?orderDirection=desc",
  GET_CARNIVALS: baseURL + "/carnivals",
  GET_CARNIVAL_REGIONS: baseURL + "/carnivals/regions",
  GET_MUSIC_TRENDING_THIS_WEEK: baseURL + "/music/trending/most-liked",
  GET_MUSIC_TRENDING_ALL_TIME: baseURL + "/music/trending/most-played",
  GET_MUSIC_NEW_RELEASES: baseURL + "/music/new-releases",
  GET_MUSIC_ALL_ALBUMS: baseURL + "/music/albums",
  GET_MUSIC_FEATURED_PLAYLIST: baseURL + "/music/featured-playlists",
  GET_MUSIC_YEARS: baseURL + "/music/year-list",
  GET_COUNTRY: baseURL + "/countries",
  GET_LOCATIONS: baseURL + "/locations",
  GET_EVENT_LOCATIONS: baseURL + "/events/locations",
  GET_EVENT_REGIONS: baseURL + "/events/regions",
  GET_GALLERY_REGIONS: baseURL + "/galleries/regions",
  GET_GALLERY_TAGS: baseURL + "/galleries/tags",
  GET_GALLERIES_LOCATIONS: baseURL + "/galleries/locations",
  GET_MUSIC_GENRES: baseURL + "/music/genres",
  GET_MUSIC_SONGS: baseURL + "/songs",
  GET_MUSIC_ARTISTS: baseURL + "/artists",
  GET_ALBUM_BY_ID: baseURL + "/music/albums/",
  GET_ARTIST_BY_ID: baseURL + "/artists/",
  GET_ARTIST_BY_SLUG: baseURL + "/artists/slug/",
  GET_ALBUM_BY_SLUG: baseURL + "/music/albums/slug/",
  GET_ARTIST_ALBUMS: baseURL + "/artists/",
  GET_CARNIVAL_DETAILS: baseURL + "/carnivals",
  POST_CARNIVAL_RATINGS: baseURL + "/carnivals",
  POST_CARNIVAL_RATINGS_BY_SLUG: baseURL + "/carnivals/slug/",
  GET_RECENTLY_PLAYED_ALBUMS: baseURL + "/music/recently-played",
  GET_CARNIVAL_DETAILS_BY_SLUG: baseURL + "/carnivals/slug/",
  GET_CARNIVAL_CLASSIFIEDS: baseURL + "/carnivals/classifieds",
  GET_CARNIVAL_RATINGS_BY_SLUG: baseURL + "/carnivals/slug/",
  GET_CARNIVAL_RATINGS: baseURL + "/carnivals/",
  GET_PLAYLIST: baseURL + "/music/playlists",
  GET_USER_PLAYLIST: baseURL + "/music/user/playlists",
  GET_PLAYLIST_DETAILS: baseURL + "/music/playlists/",
  GET_PLAYLIST_DETAILS_BY_SLUG: baseURL + "/music/playlists/slug/",
  LIKE_SONG: baseURL + "/music/",
  ADD_SONGS_TO_PLAYLIST: baseURL + "/music/playlists/add-songs",
  GET_EVENTS: baseURL + "/events",
  GET_EVENTS_GALLERIES: baseURL + "/events/galleries",
  GET_EVENTS_CARNIVALS: baseURL + "/events/carnivals",
  GET_EVENTS_CATEGORIES: baseURL + "/events/categories",
  GET_VIDEO_CATEGORIES: baseURL + "/videos/categories",
  GET_EVENTS_PROMOTERS: baseURL + "/events/promoters",
  GET_EVENTS_DETAILS: baseURL + "/events/slug/",
  GET_EVENTS_DETAILS_BY_ID: baseURL + "/events/",
  GET_TAGS: baseURL + "/tags",
  GET_ITINERARIES: baseURL + "/itineraries",
  CREATE_ITINERARIES: baseURL + "/itineraries/create",
  ADD_TO_ITINERARIES: baseURL + "/events/itineraries/add",
  GET_HIGHLIGHTED_DATES: baseURL + "/events/dates",
  GET_VIDEO_DETAILS: baseURL + "/videos/slug/",
  LIKE_VIDEO_COMMENT: baseURL + "/videos/comments/",
  LIKE_GALLERY_COMMENT: baseURL + "/galleries/comments/",
  ADD_VIDEO_TO_PLAYLIST: baseURL + "/videos/playlists/add-videos",
  CREATE_VIDEO_PLAYLIST: baseURL + "/videos/create-playlist",
  GET_VIDEO_PLAYLIST: baseURL + "/videos/playlists",
  GET_ALL_GALLERIES: baseURL + "/galleries",
  GET_MOST_LIKED_PHOTOS: baseURL + "/galleries/photos/most-liked",
  GET_GALLERY_DETAIL: baseURL + "/galleries/slug/",
  GET_GALLERY_RELATED_DATA: baseURL + "/galleries",
  GET_ALL_REVIEWS: baseURL + "/reviews",
  GET_ALL_NEWSLETTERS: baseURL + "/newsletters",
  GET_ALL_PHOTOGRAPHERS: baseURL + "/galleries/photographers",
  GET_AUTHORS: baseURL + "/reviews/authors",
  GET_CATEGORIES_REVIEWS: baseURL + "/reviews/categories",
  GET_REVIEW_DETAIL: baseURL + "/reviews/slug/",
  GET_AUTHORS_NEWSLETTERS: baseURL + "/newsletters/authors",
  GET_CATEGORIES_NEWSLETTERS: baseURL + "/newsletters/categories",
  GET_NEWSLETTER_DETAIL: baseURL + "/newsletters/slug/",
  LIKE_REVIEW_COMMENT: baseURL + "/reviews/comments/",
  LIKE_NEWSLETTER_COMMENT: baseURL + "/newsletters/comments/",
  GET_ALL_NEWSARTICLES: baseURL + "/news-articles",
  GET_AUTHORS_NEWSARTICLES: baseURL + "/news-articles/authors",
  GET_CATEGORIES_NEWSARTICLES: baseURL + "/news-articles/categories",
  GET_NEWSARTICLE_DETAIL: baseURL + "/news-articles/slug/",
  LIKE_NEWSARTICLE_COMMENT: baseURL + "/news-articles/comments/",
  HOME_BANNER: baseURL + "/home",
  LIKE_GALLERY_PHOTO: baseURL + "/galleries/photos/",
  COMMENT_GALLERY_PHOTO: baseURL + "/galleries/photos/",
  GET_USER_ITINERARIES_BY_ID: baseURL + "/itineraries/user/",
  GET_USER_ITINERARY_DETAIL: baseURL + "/itineraries/slug/",
  EDIT_ITINERARIES: baseURL + "/itineraries/",
  DELETE_ITINERARIES: baseURL + "/itineraries/",
  GET_USER_VIDEO_PLAYLISTS_BY_ID: baseURL + "/videos/",
  GET_USER_VIDEO_PLAYLISTS_DETAIL: baseURL + "/videos/slug/",
  EDIT_VIDEO_PLAYLISTS: baseURL + "/videos/",
  DELETE_VIDEO_PLAYLISTS: baseURL + "/videos/",
  DELETE_VIDEO_FROM_PLAYLISTS: baseURL + "/videos/",
  GET_USER_SONG_PLAYLISTS_BY_ID: baseURL + "/music/user/",
  GET_USER_SONG_PLAYLISTS_DETAIL: baseURL + "/music/playlists/slug/",
  EDIT_SONG_PLAYLISTS: baseURL + "/music/",
  DELETE_SONG_PLAYLISTS: baseURL + "/music/",
  DELETE_SONG_FROM_PLAYLISTS: baseURL + "/music/playlists/delete-song",
  PROMOTERS_SUBSCRIPTIONS: baseURL + "/subscriptions/promoters",
  EVENTS_SUBSCRIPTIONS: baseURL + "/subscriptions/events",
  CARNIVALS_SUBSCRIPTIONS: baseURL + "/subscriptions/carnivals",
  ARTISTS_SUBSCRIPTIONS: baseURL + "/subscriptions/artists",
  PROMOTERS_UNSUBSCRIBE: baseURL + "/subscriptions/promoters/unsubscribe",
  EVENTS_UNSUBSCRIBE: baseURL + "/subscriptions/events/unsubscribe",
  CARNIVALS_UNSUBSCRIBE: baseURL + "/subscriptions/carnivals/unsubscribe",
  ARTISTS_UNSUBSCRIBE: baseURL + "/subscriptions/artists/unsubscribe",
  GET_CLASSIFIED_DETAILS: baseURL + "/carnivals/classifieds/slug/",
  CREATE_CLASSIFIED: baseURL + "/carnivals/classifieds/create",
  GET_INBOX_DATA: baseURL + "/carnivals/classifieds/inbox",
  SEND_MESSAGE: baseURL + "/carnivals/classifieds/message",
  GET_ADS: baseURL + "/ad",
  STREAM_VIDEO: baseURL + "/videos/stream/",
  STREAM_MUSIC: baseURL + "/music/stream/",
  DELETE_COMMENT: baseURL + "/comment/",
  GET_PAGES: baseURL + "/pages",
  DELETE_CLASSIFIED: baseURL + "/carnivals/classifieds/",
  GET_NOTIFICATIONS: baseURL + "/notifications",
  GET_READ_NOTIFICATIONS: baseURL + "/notifications/past",
  READ_NOTIFICATIONS: baseURL + "/notifications/",
};
