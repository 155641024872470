import React, { useContext, useEffect, useRef, useState } from "react";
import NavContainer from "../../elements/NavContainer/NavContainer";
import Label from "../../elements/label/Label";
import Chip from "../../components/chip/Chip";
import EventCss from "./Events.module.css";
import FullWidthEventList from "../../elements/Flatlist/FullWidthEventList";
import { itinerary } from "./data/data";
import dayjs from "dayjs";
import { FontSizes } from "../../config";
import EventFilter from "./elements/EventFilter";
import { FaCalendarAlt, FaCaretDown, FaCaretUp } from "react-icons/fa";
import CountryFooter from "../../elements/country-footer/CountryFooter";
import { Grid, Skeleton } from "@mui/material";
import EventSlides from "../../elements/event-slides/EventSlides";
import { IoPersonCircleOutline } from "react-icons/io5";
import { connect } from "react-redux";
import Button from "../../components/button/Button";
import NoData from "../../elements/no-data/NoData";
import EventLongCardLoader from "../../elements/skeletonLoaders/EventLongCardLoader";
import CategoriesLoader from "../../elements/skeletonLoaders/CategoriesLoader";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Context } from "../../Context";
import ViewMore from "../../elements/view-more/ViewMore";
import CategoriesList from "../../elements/categories/CategoriesList";
import MetaTags from "../../MetaTags";
import EventCardLoader from "../../elements/skeletonLoaders/EventCardLoader";
import PromotersLoader from "../../elements/skeletonLoaders/PromoterLoader";
import JumpToDate from "./elements/JumpToDate";
import axios from "axios";
import { PER_PAGE_COUNT, SERVER_URL } from "../../Constants.ts";
import ReactGA from "react-ga4";
import DynamicContentInjector from "../../elements/DynamicContentInjector/DynamicContentInjector.js";

const tagsArray = [
  {
    name: "All",
    slug: "all",
  },
  {
    name: "Featured",
    slug: "featured",
  },
  {
    name: "TJJ Family",
    slug: "tjj_family",
  },
];

const EventHome = ({ isLoggedIn, user }) => {
  const [triggerReset, setTriggerReset] = useState(false);
  const [selectedChip, setSelectedChip] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  const [date, setDate] = useState(null);
  const [showDate, setShowDate] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [featuredEvents, setFeaturedEvents] = useState(null);
  const [events, setEvents] = useState([]);
  const [filter, setFilter] = useState({});
  let [searchParams, setSearchParams] = useSearchParams();
  const { setPageTitle } = useContext(Context);
  const [params, setParams] = useState({});
  const filterRef = useRef();
  let { state } = useLocation();
  const [optionalTitle, setOptionalTitle] = useState("");
  const [featuredEventsIsLoading, setFeaturedEventsIsLoading] = useState(true);
  const [eventsIsLoading, setEventsIsLoading] = useState(true);
  const [allPromotersIsLoading, setAllPromotersIsLoading] = useState(true);
  const [promotersIsLoading, setPromotersIsLoading] = useState(true);
  const [allCategoriesIsLoading, setAllCategoriesIsLoading] = useState(true);
  const [categoriesIsLoading, setCategoriesIsLoading] = useState(true);
  const [allPromoters, setAllPromoters] = useState(null);
  const [promoters, setPromoters] = useState(null);
  const [allCategories, setAllCategories] = useState(null);
  const [categories, setCategories] = useState(null);
  const [mostViewedEvents, setMostViewedEvents] = useState(null);
  const [mostViewedEventsIsLoading, setMostViewedEventsIsLoading] =
    useState(true);
  const [highlightedDays, setHighlightedDays] = React.useState([]);
  const navigate = useNavigate();
  const [countryList, setCountryList] = useState(null);
  const [countryListIsLoading, setCountryListIsLoading] = useState(true);
  const [tags, setTags] = useState(null);
  const [carnivals, setCarnivals] = useState(null);
  const [tagsIsLoading, setTagsIsLoading] = useState(true);
  const [carnivalsIsLoading, setCarnivalsIsLoading] = useState(true);
  const [moreEventsIsLoading, setMoreEventsIsLoading] = useState(false);
  const [eventsLastPage, setEventsLastPage] = useState(null);
  const [eventsPageNumber, setEventsPageNumber] = useState(1);
  const [dateCalendarIsLoading, setDateCalendarIsLoading] = useState(true);
  const requestAbortController = React.useRef(null);
  const [bannerAd, setBannerAd] = useState(null);
  const [status, setStatus] = useState(null);
  const [bannerAd2, setBannerAd2] = useState(null);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/events", title: "Event Home" });
  }, []);

  const handleGetSidebarBanner = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ADS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          type: "sidebar ad square",
        },
      });
      if (res.status === 200) {
        if (res.data) {
          setBannerAd(res.data);
        }
      }
    } catch (err) {
      setBannerAd(null);
    }
  };

  const handleGetSecondSidebarBanner = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ADS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          type: "skyscraper ad",
        },
      });
      if (res.status === 200) {
        if (res.data) {
          setBannerAd2(res.data);
        }
      }
    } catch (err) {
      setBannerAd2(null);
    }
  };

  useEffect(() => {
    handleGetSidebarBanner();
    handleGetSecondSidebarBanner();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setPageTitle("Events");
  }, []);

  const getCountry = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_EVENT_REGIONS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.ALL_DATA,
        },
      });
      if (res.status === 200) {
        if (res?.data?.data && res?.data?.data?.length !== 0) {
          setCountryList(res?.data?.data);
        }
        setCountryListIsLoading(false);
      }
    } catch (err) {
      setCountryListIsLoading(false);
    }
  };

  //This useEffect is collecting the search params from the url and storing them into the filters.
  useEffect(() => {
    if (searchParams.size !== 0) {
      let params = {};
      if (searchParams?.get("location")) {
        params = {
          ...params,
          location: searchParams.get("location"),
        };
      }
      if (searchParams?.getAll("category").length !== 0) {
        params = {
          ...params,
          category: searchParams.getAll("category"),
        };
      }
      if (searchParams?.getAll("promoter").length !== 0) {
        params = {
          ...params,
          promoter: searchParams.getAll("promoter"),
        };
      }
      if (searchParams?.getAll("tag").length !== 0) {
        params = {
          ...params,
          tag: searchParams.getAll("tag"),
        };
      }
      if (searchParams?.getAll("carnivals").length !== 0) {
        params = {
          ...params,
          carnivals: searchParams.getAll("carnivals"),
        };
      }
      if (searchParams?.getAll("time").length !== 0) {
        params = {
          ...params,
          time: searchParams.getAll("time"),
        };
      }
      if (searchParams?.get("search")) {
        params = {
          ...params,
          search: searchParams.get("search"),
        };
      }
      if (searchParams?.get("startDate")) {
        params = {
          ...params,
          startDate: searchParams.get("startDate"),
        };
      }
      if (searchParams?.get("endDate")) {
        params = {
          ...params,
          endDate: searchParams.get("endDate"),
        };
      }
      if (searchParams?.get("region")) {
        params = {
          ...params,
          region: searchParams.get("region"),
        };
      }

      if (
        params &&
        Object.keys(params).length > 0 &&
        Object.keys(filter)?.length === 0
      ) {
        handleSearch(params);
      }
    }
  }, [searchParams]);

  useEffect(() => {
    if (searchParams.size !== 0) {
      if (searchParams.size === 1) {
        if (searchParams?.get("tag") !== null) {
          setOptionalTitle("By Tag");
        } else if (searchParams?.get("category") !== null) {
          setOptionalTitle("By Category");
        } else if (searchParams?.get("promoter") !== null) {
          setOptionalTitle("By promoter");
        } else {
          setOptionalTitle("");
        }
      } else {
        setOptionalTitle("Filtered Results");
      }
    } else {
      setOptionalTitle("");
    }
  }, [searchParams]);

  //This function is used to show the filters and calendar in the mobile view port.
  const handleShowDd = (item) => {
    if (item === "filter") {
      if (showDate) {
        setShowDate(false);
      }
      setShowFilter(!showFilter);
    } else {
      if (showFilter) {
        setShowFilter(false);
      }
      setShowDate(!showDate);
    }
  };

  useEffect(() => {
    if (state?.selectedTab) {
      handleChange(state.selectedTab);
    }
  }, [state]);

  useEffect(() => {
    if (width < 431) {
      setShowDate(false);
      setShowFilter(false);
    }
  }, [width]);

  const handleChange = (item) => {
    ReactGA.event({
      category: "Chip",
      action: "Chip updated",
      label: `${item} chip updated on event's home page`,
    });
    ReactGA.event({
      category: "Chip",
      action: `${item} chip updated on event's home page`,
      label: `${item} chip updated on event's home page`,
    });
    setSelectedChip(item);
    handleSearchParam("selectedChip", item);
  };

  //Creates the search params based on the keys and their values
  const handleSearchParam = (key, value) => {
    setParams((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleLocation = (item) => {
    setTriggerReset(true);
    let params = {
      region: item,
    };
    setTimeout(() => {
      handleSearch(params);
      filterRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 1000);
  };

  const handlePromoters = (item) => {
    setTriggerReset(true);
    let params = {
      promoter: [item],
    };
    setTimeout(() => {
      handleSearch(params);
      filterRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 1000);
  };

  const handleCategories = (item) => {
    setTriggerReset(true);
    let params = {
      category: [item],
    };
    setEventsIsLoading(true);
    setTimeout(() => {
      handleSearch(params);
      filterRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 1000);
  };

  useEffect(() => {
    getFeaturedEvents();
    getMostViewedEvents();
    getEventsCategories();
    getEventsPromoters();
    getAllEventsCategories();
    getAllEventsPromoters();
    getCountry();
    getAllEventsCarnivals();
    getAllEventsTags();
    fetchHighlightedDays();
  }, []);

  const getFeaturedEvents = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_EVENTS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.FEATURED_EVENTS,
          type: "featured",
        },
      });
      if (res.status === 200) {
        setFeaturedEvents(res?.data?.data);
        setFeaturedEventsIsLoading(false);
      }
    } catch (err) {
      setFeaturedEventsIsLoading(false);
    }
  };

  const getMostViewedEvents = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_EVENTS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.EVENTS,
          type: "most_viewed",
        },
      });
      if (res.status === 200) {
        setMostViewedEvents(res?.data?.data);
        setMostViewedEventsIsLoading(false);
      }
    } catch (err) {
      setMostViewedEventsIsLoading(false);
    }
  };

  const getEventsData = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_EVENTS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: eventsPageNumber,
          items: PER_PAGE_COUNT.ALL_EVENTS,
          type: selectedChip,
          orderDirection: "asc",
          timestamped: true,
          ...(filter?.location && {
            locations: filter?.location,
          }),
          ...(filter?.search && {
            search: filter?.search,
          }),
          ...(filter?.tag && {
            tags: filter?.tag,
          }),
          ...(filter?.carnivals && {
            carnivals: filter?.carnivals,
          }),
          ...(filter?.category && {
            categories: filter?.category,
          }),
          ...(filter?.promoter && {
            promoters: filter?.promoter,
          }),
          ...(filter?.time && {
            time: filter?.time,
          }),
          ...(!filter?.startDate &&
            date && {
              startDate: date,
            }),
          ...(!filter?.endDate &&
            date && {
              endDate: date,
            }),
          ...(filter?.startDate && {
            startDate: filter?.startDate,
          }),
          ...(filter?.endDate && {
            endDate: filter?.endDate,
          }),
          ...(filter?.region && {
            region: filter?.region,
          }),
        },
      });
      if (res.status === 200) {
        setStatus(res.status);
        if (res?.data && res?.data?.length !== 0 && res?.data?.events) {
          setEventsLastPage(res?.data?.last_page);
          if (events?.length === 0) {
            setEvents(res?.data?.events);
          } else {
            setEvents((prev) => [...prev, ...res?.data?.events]);
          }
        } else {
          setEvents([]);
          setEventsLastPage(null);
        }
        setEventsIsLoading(false);
        if (moreEventsIsLoading) {
          setMoreEventsIsLoading(false);
        }
      } else {
        setEvents([]);
        setEventsLastPage(null);
        setEventsIsLoading(false);
        if (moreEventsIsLoading) {
          setMoreEventsIsLoading(false);
        }
      }
    } catch (err) {
      setEvents([]);
      setEventsIsLoading(false);
      if (moreEventsIsLoading) {
        setMoreEventsIsLoading(false);
      }
    }
  };

  const loadMoreEvents = () => {
    if (eventsLastPage != null && eventsPageNumber <= eventsLastPage) {
      setMoreEventsIsLoading(true);
      setEventsPageNumber(eventsPageNumber + 1);
    }
  };

  useEffect(() => {
    if (selectedChip && eventsPageNumber !== 1) {
      getEventsData();
    }
  }, [eventsPageNumber]);

  const getEventsCategories = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_EVENTS_CATEGORIES, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.EVENT_CATEGORIES_PROMOTERS,
        },
      });
      if (res.status === 200) {
        if (res?.data?.event_categories?.data?.length !== 0) {
          setCategories(res?.data?.event_categories?.data);
        }
        setCategoriesIsLoading(false);
      }
    } catch (err) {
      setCategoriesIsLoading(false);
    }
  };
  const getEventsPromoters = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_EVENTS_PROMOTERS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.EVENT_CATEGORIES_PROMOTERS,
        },
      });
      if (res.status === 200) {
        if (res?.data?.promoters?.data?.length !== 0) {
          setPromoters(res?.data?.promoters?.data);
        }
        setPromotersIsLoading(false);
      }
    } catch (err) {
      setPromotersIsLoading(false);
    }
  };
  const getAllEventsCategories = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_EVENTS_CATEGORIES, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.EVENT_ALL_CATEGORIES_PROMOTERS,
        },
      });
      if (res.status === 200) {
        setAllCategories(res?.data?.event_categories?.data);
        setAllCategoriesIsLoading(false);
      }
    } catch (err) {
      setAllCategoriesIsLoading(false);
    }
  };
  const getAllEventsPromoters = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_EVENTS_PROMOTERS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.EVENT_ALL_CATEGORIES_PROMOTERS,
        },
      });
      if (res.status === 200) {
        setAllPromoters(res?.data?.promoters?.data);
        setAllPromotersIsLoading(false);
      }
    } catch (err) {
      setAllPromotersIsLoading(false);
    }
  };

  const getAllEventsCarnivals = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_EVENTS_CARNIVALS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.ALL_DATA,
        },
      });
      if (res.status === 200) {
        if (res?.data && res?.data?.carnivals) {
          setCarnivals(res?.data?.carnivals?.data);
        }
        setCarnivalsIsLoading(false);
      }
    } catch (err) {
      setCarnivalsIsLoading(false);
    }
  };

  const getAllEventsTags = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_TAGS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          type: "event",
        },
      });
      if (res.status === 200) {
        setTags(res?.data?.data);
        setTagsIsLoading(false);
      }
    } catch (err) {
      setTagsIsLoading(false);
    }
  };

  useEffect(() => {
    setEventsPageNumber(1);
    setEvents([]);
    setEventsIsLoading(true);
    if (searchParams.size === 1 && searchParams.has("selectedChip")) {
      if (selectedChip) {
        getEventsData();
      }
    } else if (searchParams.size === 1 && searchParams.has("region")) {
      if (selectedChip) {
        getEventsData();
      }
    } else if (searchParams.size !== 0 || Object.keys(filter)?.length !== 0) {
      if (Object.keys(filter)?.length !== 0) {
        getEventsData();
      } else if (date) {
        getEventsData();
      }
    } else {
      if (selectedChip) {
        getEventsData();
      }
    }
  }, [date, selectedChip, filter]);

  useEffect(() => {
    if (params && Object.keys(params).length > 0) {
      setSearchParams(params);
    }
  }, [params]);

  const handleSearch = (item) => {
    ReactGA.event({
      category: "Filter",
      action: "Results filtered",
      label: "Results filtered on event home page",
    });
    ReactGA.event({
      category: "Filter",
      action: "Results filtered on event home page",
      label: "Results filtered on event home page",
    });
    setFilter(item);
    Object.entries(item).map((i) => {
      handleSearchParam(i[0], i[1]);
    });
  };

  useEffect(() => {
    if (searchParams) {
      if (searchParams?.get("selectedChip")) {
        handleChange(searchParams?.get("selectedChip"));
      } else {
        setSelectedChip("all");
      }
      if (searchParams?.get("date")) {
        if (date !== searchParams?.get("date")) {
          handleSetDate(searchParams?.get("date"));
        }
      }
    }
  }, [searchParams]);

  //This function is used to remove all the filters(even from the url) of event that are applied.
  const handleResetAll = () => {
    setFilter({});
    const filters = [
      "location",
      "region",
      "search",
      "tag",
      "category",
      "promoter",
      "time",
      "startDate",
      "endDate",
      "carnivals",
    ];
    filters.map((item) => {
      if (searchParams.has(item)) {
        searchParams.delete(item);
        setSearchParams(searchParams);
        delete params[item];
      }
    });
  };

  const handleSetDate = (val) => {
    ReactGA.event({
      category: "Jump to date",
      action: "Jump to date selected",
      label: "Jump to date(${val}) selected on event's home page",
    });
    ReactGA.event({
      category: "Jump to date",
      action: `Jump to date(${val}) selected on event's home page`,
      label: "Jump to date(${val}) selected on event's home page",
    });
    setDate(val);
    handleSearchParam("date", val);
  };

  const handleClick = (item) => {
    navigate(item);
    window.scrollTo(0, 0);
  };

  const resetCalendar = () => {
    if (searchParams.has("date")) {
      setDate(null);
      searchParams.delete("date");
      setSearchParams(searchParams);
      delete params["date"];
    }
  };

  //Used to fetch all the highlighted days on which the events exist.
  const fetchHighlightedDays = async (date) => {
    const controller = new AbortController();
    let param = {};
    if (date) {
      const d = new Date(date);
      param = {
        month: d.getMonth() + 1,
        year: d.getFullYear(),
      };
    } else {
      const d = new Date();
      param = {
        month: d.getMonth() + 1,
        year: d.getFullYear(),
      };
    }
    try {
      const res = await axios.get(SERVER_URL.GET_HIGHLIGHTED_DATES, {
        headers: {
          Accept: "application/json",
        },
        params: param,
      });
      if (res.status === 200) {
        //This map function is used to create a key value pair of date with its proper DateTime string. For example: { 19:"2024-01-19T00:05:09"}
        const op = res?.data?.map((item) => {
          const d = dayjs(item).date();
          return { [d]: item };
        });
        if (op) {
          setHighlightedDays(op);
        } else {
          setHighlightedDays([]);
        }
        setDateCalendarIsLoading(false);
      }
    } catch (err) {
      setDateCalendarIsLoading(false);
    }
    requestAbortController.current = controller;
  };

  const handleMonthChange = (date) => {
    if (requestAbortController.current) {
      requestAbortController.current.abort();
    }
    setDateCalendarIsLoading(true);
    setHighlightedDays([]);
    fetchHighlightedDays(date);
  };

  const handleYearChange = (date) => {
    if (requestAbortController.current) {
      requestAbortController.current.abort();
    }
    setDateCalendarIsLoading(true);
    setHighlightedDays([]);
    fetchHighlightedDays(date);
  };

  //This will reset all the filters, date or selected chips that are applied to filter the results.
  const handleReset = () => {
    if (Object.keys(filter)?.length !== 0) {
      setTriggerReset(true);
    }
    if (date !== null) {
      setDate(null);
    }
    if (selectedChip !== "all") {
      setSelectedChip("all");
    }
    setParams({});
    setSearchParams({});
  };

  const handleSubmitEvent = () => {
    const link = "https://staging.trinijunglejuice.com/promoter/login";
    const aTag = document.createElement("a");
    aTag.setAttribute("href", link);
    aTag.setAttribute("target", "_blank");
    aTag.click();
    ReactGA.event({
      category: "Submit",
      action: "Submit an event button clicked",
      label: "Submit an event button clicked on event's home page",
    });
  };

  return (
    <NavContainer>
      <MetaTags
        author={"Trini Jungle Juice"}
        title={"Events"}
        url={window.location.href}
        type={"website"}
        image={"../../assets/images/tjj-logo.png"}
      />
      <div className={EventCss.mainDiv}>
        <img
          src={require("../../assets/images/submitAnEvent.png")}
          className={EventCss.submitImgSm}
          onClick={handleSubmitEvent}
        />
        <div className={EventCss.fullWidthContainer}>
          {featuredEventsIsLoading ? (
            <Skeleton
              sx={{ bgcolor: "grey.900", marginBottom: 3 }}
              animation="wave"
              variant="text"
              width={width > 431 ? "30%" : "40%"}
              height={50}
            />
          ) : (
            featuredEvents &&
            featuredEvents?.length !== 0 && (
              <Label label={"Featured Events"} size="md" />
            )
          )}
          {featuredEventsIsLoading ? (
            <div className={EventCss.flexDrR}>
              {Array.from({ length: 4 }, (_, index) => {
                return <EventCardLoader key={index} />;
              })}
            </div>
          ) : (
            featuredEvents &&
            featuredEvents?.length !== 0 && (
              <>
                <EventSlides data={featuredEvents} />
              </>
            )
          )}
          {(featuredEventsIsLoading ||
            (featuredEvents && featuredEvents?.length !== 0)) && (
            <div className={EventCss.mgb} />
          )}
        </div>
        <div className={EventCss.fullWidthContainerFd}>
          <div className={EventCss.firstContainer}>
            <div className={EventCss.flexDr}>
              <Label
                size="lg"
                label={
                  optionalTitle !== ""
                    ? "All Events " + optionalTitle
                    : "All Events"
                }
              />
              <div className={EventCss.iconsSm}>
                <div className={EventCss.iconsSmDiv}>
                  <FaCalendarAlt
                    onClick={() => handleShowDd("calendar")}
                    className={EventCss.calendarSm}
                  />
                  {showDate ? (
                    <FaCaretUp className={EventCss.upDown} />
                  ) : (
                    <FaCaretDown className={EventCss.upDown} />
                  )}
                </div>
                <div className={EventCss.iconsSmDiv}>
                  <img
                    src={require("../../assets/images/filter.png")}
                    className={EventCss.filterSm}
                    onClick={() => handleShowDd("filter")}
                  />
                  {showFilter ? (
                    <FaCaretUp className={EventCss.upDown} />
                  ) : (
                    <FaCaretDown className={EventCss.upDown} />
                  )}
                </div>
              </div>
            </div>
            {showDate && width < 900 && (
              <JumpToDate
                date={date}
                handleSetDate={handleSetDate}
                highlightedDaysKeys={highlightedDays}
                resetCalendar={resetCalendar}
                handleMonthChange={handleMonthChange}
                handleYearChange={handleYearChange}
                isLoading={dateCalendarIsLoading}
              />
            )}
            {showFilter && width < 900 && (
              <div ref={filterRef}>
                {tagsIsLoading ||
                allCategoriesIsLoading ||
                allPromotersIsLoading ||
                carnivalsIsLoading ||
                countryListIsLoading ? (
                  <Skeleton
                    sx={{ bgcolor: "grey.900", marginTop: 2 }}
                    animation="wave"
                    variant="rounded"
                    width={"100%"}
                    height={440}
                  />
                ) : (
                  <EventFilter
                    triggerReset={triggerReset}
                    filter={filter}
                    onSearch={(item) => handleSearch(item)}
                    tagsOptions={tags}
                    carnivalOptions={carnivals}
                    categoryOptions={allCategories}
                    promoterOptions={allPromoters}
                    countryArray={countryList}
                    onReset={handleResetAll}
                    setTriggerReset={setTriggerReset}
                  />
                )}
              </div>
            )}
            {bannerAd &&
              (bannerAd?.banner_image_url ? (
                <div className={EventCss.smBanner}>
                  <img
                    className={EventCss.BannerAds}
                    src={bannerAd?.banner_image_url}
                  />
                </div>
              ) : (
                bannerAd?.content && (
                  <div className={EventCss.smBanner}>
                    <DynamicContentInjector
                      className={EventCss.BannerAd}
                      htmlContent={bannerAd?.content}
                    />
                  </div>
                )
              ))}
            {bannerAd2 &&
              (bannerAd2?.banner_image_url ? (
                <div className={EventCss.smBanner}>
                  <img
                    className={EventCss.BannerAds}
                    src={bannerAd2?.banner_image_url}
                  />
                </div>
              ) : (
                bannerAd2?.content && (
                  <div className={EventCss.smBanner}>
                    <DynamicContentInjector
                      className={EventCss.BannerAd}
                      htmlContent={bannerAd2?.content}
                    />
                  </div>
                )
              ))}
            <div className={EventCss.chipsArray}>
              {tagsArray.map((item, index) => {
                return (
                  <Chip
                    label={item?.name}
                    size={"md"}
                    backgroundColor={
                      selectedChip === item?.slug ? "#ff0000" : ""
                    }
                    handleClick={() => handleChange(item?.slug)}
                    key={index}
                  />
                );
              })}
            </div>
            {eventsIsLoading ? (
              Array.from({ length: 5 }, (_, index) => (
                <div key={index} style={{ maxWidth: "60%" }}>
                  <EventLongCardLoader />
                  <div className={EventCss.mgb} />
                </div>
              ))
            ) : (
              <>
                {events?.length !== 0 ? (
                  <>
                    <FullWidthEventList
                      isLoggedIn={isLoggedIn}
                      event={events}
                      itinerary={itinerary}
                      user={user}
                    />
                    {moreEventsIsLoading &&
                      Array.from({ length: 5 }, (_, index) => (
                        <div
                          key={index}
                          style={{ maxWidth: "55%", marginTop: 20 }}
                        >
                          <EventLongCardLoader />
                          <div className={EventCss.mgb} />
                        </div>
                      ))}
                    {events?.length !== 0 &&
                    eventsLastPage != null &&
                    eventsPageNumber < eventsLastPage &&
                    !eventsIsLoading &&
                    !moreEventsIsLoading ? (
                      <ViewMore loadMore={true} onClick={loadMoreEvents} />
                    ) : (
                      <div className={EventCss.mgb} />
                    )}
                  </>
                ) : (
                  <>
                    <NoData errorCode={status} />
                    {(selectedChip !== "all" ||
                      Object.keys(filter).length !== 0 ||
                      date !== null) && (
                      <Button
                        onClick={() => handleReset()}
                        label="Reset Filters"
                        styles={{ fontWeight: "600", fontSize: FontSizes.text }}
                      />
                    )}
                    <div className={EventCss.mgb} />
                  </>
                )}
              </>
            )}
          </div>
          <div className={EventCss.secondContainer}>
            <img
              src={require("../../assets/images/submitAnEvent.png")}
              className={EventCss.submitImg}
              onClick={handleSubmitEvent}
            />
            <JumpToDate
              date={date}
              handleSetDate={handleSetDate}
              highlightedDaysKeys={highlightedDays}
              resetCalendar={resetCalendar}
              handleMonthChange={handleMonthChange}
              handleYearChange={handleYearChange}
              isLoading={dateCalendarIsLoading}
            />
            <div ref={filterRef}>
              {tagsIsLoading ||
              allCategoriesIsLoading ||
              allPromotersIsLoading ||
              carnivalsIsLoading ||
              countryListIsLoading ? (
                <Skeleton
                  sx={{ bgcolor: "grey.900", marginTop: 2 }}
                  animation="wave"
                  variant="rounded"
                  width={"100%"}
                  height={440}
                />
              ) : (
                <EventFilter
                  triggerReset={triggerReset}
                  filter={filter}
                  onSearch={(item) => handleSearch(item)}
                  tagsOptions={tags}
                  carnivalOptions={carnivals}
                  categoryOptions={allCategories}
                  promoterOptions={allPromoters}
                  countryArray={countryList}
                  onReset={handleResetAll}
                  setTriggerReset={setTriggerReset}
                />
              )}
            </div>
            {bannerAd &&
              (bannerAd?.banner_image_url ? (
                <img
                  className={EventCss.BannerAds}
                  src={bannerAd?.banner_image_url}
                />
              ) : (
                bannerAd?.content && (
                  <DynamicContentInjector
                    className={EventCss.BannerAd}
                    htmlContent={bannerAd?.content}
                  />
                )
              ))}
            {bannerAd2 &&
              (bannerAd2?.banner_image_url ? (
                <img
                  className={EventCss.BannerAds}
                  src={bannerAd2?.banner_image_url}
                />
              ) : (
                bannerAd2?.content && (
                  <DynamicContentInjector
                    className={EventCss.BannerAd}
                    htmlContent={bannerAd2?.content}
                  />
                )
              ))}
          </div>
        </div>
        <div className={EventCss.fullWidthContainer}>
          {mostViewedEventsIsLoading ? (
            <Skeleton
              sx={{ bgcolor: "grey.900", marginBottom: 3 }}
              animation="wave"
              variant="text"
              width={width > 431 ? "30%" : "40%"}
              height={50}
            />
          ) : (
            mostViewedEvents &&
            mostViewedEvents?.length !== 0 && (
              <Label label={"Most Viewed Events"} size="md" />
            )
          )}
          {mostViewedEventsIsLoading ? (
            <div className={EventCss.flexDrR}>
              {Array.from({ length: 4 }, (_, index) => {
                return <EventCardLoader key={index} />;
              })}
            </div>
          ) : (
            mostViewedEvents &&
            mostViewedEvents?.length !== 0 && (
              <>
                <EventSlides data={mostViewedEvents} />
              </>
            )
          )}
          {(mostViewedEventsIsLoading ||
            (mostViewedEvents && mostViewedEvents?.length !== 0)) && (
            <div className={EventCss.mgb} />
          )}
        </div>
      </div>
      {(categoriesIsLoading || (categories && categories?.length !== 0)) && (
        <div className={EventCss.redHead}>Events by Categories</div>
      )}
      {categoriesIsLoading ? (
        <Grid className={EventCss.topBox} container columnGap={3}>
          <CategoriesLoader />
          <div className={EventCss.mgb} />
        </Grid>
      ) : (
        categories &&
        categories?.length !== 0 && (
          <>
            <Grid className={EventCss.topBox} container columnGap={3}>
              {categories?.map((item, index) => {
                return (
                  <CategoriesList
                    key={index}
                    item={item}
                    onClick={() => handleCategories(item?.slug)}
                  />
                );
              })}
            </Grid>
            <ViewMore onClick={() => handleClick("/events/categories")} />
          </>
        )
      )}

      {(promotersIsLoading || (promoters && promoters?.length !== 0)) && (
        <div className={EventCss.redHead}>Events by Promoters</div>
      )}
      {promotersIsLoading ? (
        <Grid className={EventCss.topBox} container columnGap={3}>
          <PromotersLoader />
          <div className={EventCss.mgb} />
        </Grid>
      ) : (
        promoters &&
        promoters?.length !== 0 && (
          <>
            <Grid className={EventCss.topBox} container columnGap={3}>
              {promoters?.map((item, index) => {
                return (
                  <Grid
                    className={EventCss.topImgContainer}
                    item
                    key={index}
                    xs={12}
                    sm={5.3}
                    md={2.2}
                    onClick={() => handlePromoters(item?.slug)}
                  >
                    <div className={EventCss.minWid}>
                      {item?.profile_photo_url === null ? (
                        <IoPersonCircleOutline
                          className={EventCss.promoterIcon}
                        />
                      ) : (
                        <img
                          src={item.profile_photo_url}
                          className={EventCss.promoterImg}
                          alt="Promoter Image"
                        />
                      )}
                    </div>
                    <div className={EventCss.top}>{item?.display_name}</div>
                  </Grid>
                );
              })}
            </Grid>
            <ViewMore onClick={() => handleClick("/promoters")} />
          </>
        )
      )}

      <CountryFooter
        by={"Events"}
        handleClick={(item) => handleLocation(item)}
      />
    </NavContainer>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  user: state.auth.user,
});

export default connect(mapStateToProps)(EventHome);
