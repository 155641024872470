import React, { useEffect, useState } from "react";
import NavContainer from "../../elements/NavContainer/NavContainer";
import GlobalSearchCss from "./GlobalSearch.module.css";
import { styled } from "@mui/material/styles";
import { Drawer, Grid, Tab, Tabs, TextField } from "@mui/material";
import { FontSizes } from "../../config";
import { Context } from "../../Context";
import { useContext } from "react";
import IconButton from "../../components/icon-button/IconButton";
import { Search } from "@mui/icons-material";
import Filter from "./elements/Filter";
import { useSearchParams } from "react-router-dom";
import { PER_PAGE_COUNT, SERVER_URL } from "../../Constants.ts";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { addToQueue } from "../../actions.js";
import { selectMusic, setMusic } from "../../slices/MusicReducer.js";
import MetaTags from "../../MetaTags.js";
import All from "./elements/tabs/All.js";
import News from "./elements/tabs/News.js";
import Carnival from "./elements/tabs/Carnival.js";
import Event from "./elements/tabs/Event.js";
import Gallery from "./elements/tabs/Gallery.js";
import Videos from "./elements/tabs/Videos.js";
import Song from "./elements/tabs/Song.js";
import Artists from "./elements/tabs/Artist.js";
import Albums from "./elements/tabs/Albums.js";
import Genres from "./elements/tabs/Genres.js";
import Reviews from "./elements/tabs/Reviews.js";
import Swal from "sweetalert2";
import ReactGA from "react-ga4";

const tabs = [
  { label: "All", slug: "all", index: 0 },
  { label: "News Articles", slug: "newsarticles", index: 1 },
  { label: "Carnivals", slug: "carnivals", index: 2 },
  { label: "Events", slug: "events", index: 3 },
  { label: "Photos", slug: "photos", index: 4 },
  { label: "Videos", slug: "videos", index: 5 },
  { label: "Music", slug: "music", index: 6 },
  { label: "Artists", slug: "artists", index: 7 },
  { label: "Albums", slug: "albums", index: 8 },
  { label: "Genres", slug: "genres", index: 9 },
  { label: "Reviews", slug: "reviews", index: 10 },
];

export default function GlobalSearch() {
  const [value, setValue] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(null);
  const [moreEventsIsLoading, setMoreEventsIsLoading] = useState(false);
  const [eventsLastPage, setEventsLastPage] = useState(null);
  const [eventsPageNumber, setEventsPageNumber] = useState(1);
  const [eventsIsLoading, setEventsIsLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [search, setSearch] = useState("");
  const [searchingStarted, setSearchingStarted] = useState(false);
  const { setPageTitle } = useContext(Context);
  let [searchParams, setSearchParams] = useSearchParams();
  const [params, setParams] = useState({
    all: {},
    newsarticles: {},
    carnivals: {},
    events: {},
    photos: {},
    videos: {},
    music: {},
    artists: {},
    albums: {},
    genres: {},
    reviews: {},
  });
  const [filters, setFilters] = useState({
    all: {},
    newsarticles: {},
    carnivals: {},
    events: {},
    photos: {},
    videos: {},
    music: {},
    artists: {},
    albums: {},
    genres: {},
    reviews: {},
  });
  const [tagType, setTagType] = useState("all");
  const [tagsList, setTagsList] = useState([]);
  const [url, setUrl] = useState(null);
  const [categoryList, setCategoryList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [allPromoters, setAllPromoters] = useState([]);
  const [photographers, setPhotographers] = useState([]);
  const [authorUrl, setAuthorUrl] = useState(null);
  const [authorsList, setAuthorsList] = useState([]);
  const [galleriesLastPage, setGalleriesLastPage] = useState(null);
  const [galleriesPageNumber, setGalleriesPageNumber] = useState(1);
  const [galleriesIsLoading, setGalleriesIsLoading] = useState(true);
  const [moreGalleriesIsLoading, setMoreGalleriesIsLoading] = useState(false);
  const [galleries, setGalleries] = useState([]);
  const [artists, setArtists] = useState([]);
  const [moreVideosIsLoading, setMoreVideosIsLoading] = useState(false);
  const [videosPageNumber, setVideosPageNumber] = useState(1);
  const [videosLastPage, setVideosLastPage] = useState(null);
  const [videos, setVideos] = useState([]);
  const [videosIsLoading, setVideosIsLoading] = useState(true);
  const [carnivalsPageNumber, setCarnivalsPageNumber] = useState(1);
  const [carnivalsLastPage, setCarnivalsLastPage] = useState(null);
  const [carnivals, setCarnivals] = useState([]);
  const [carnivalsIsLoading, setCarnivalsIsLoading] = useState(true);
  const [moreCarnivalsIsLoading, setMoreCarnivalsIsLoading] = useState(false);
  const [newsArticlesLastPage, setNewsArticlesLastPage] = useState(null);
  const [newsArticlesPageNumber, setNewsArticlesPageNumber] = useState(1);
  const [newsArticlesLoading, setNewsArticlesLoading] = useState(true);
  const [newsArticles, setNewsArticles] = useState([]);
  const [moreNewsArticlesIsLoading, setMoreNewsArticlesIsLoading] =
    useState(false);
  const [moreReviewsIsLoading, setMoreReviewsIsLoading] = useState(false);
  const [ReviewsPageNumber, setReviewsPageNumber] = useState(1);
  const [ReviewsLastPage, setReviewsLastPage] = useState(null);
  const [reviewsIsLoading, setReviewsIsLoading] = useState(true);
  const [reviews, setReviews] = useState([]);
  const [totalReviews, setTotalReviews] = useState(0);
  const [totalCarnivals, setTotalCarnivals] = useState(0);
  const [totalVideos, setTotalVideos] = useState(0);
  const [totalGalleries, setTotalGalleries] = useState(0);
  const [totalEvents, setTotalEvents] = useState(0);
  const [totalNewsArticles, setTotalNewsArticles] = useState(0);
  const [totalArtists, setTotalArtists] = useState(0);
  const [totalAlbums, setTotalAlbums] = useState(0);
  const [totalGenres, setTotalGenres] = useState(0);
  const [totalSongs, setTotalSongs] = useState(0);
  const [artistsLastPage, setArtistsLastPage] = useState(null);
  const [artistsPageNumber, setArtistsPageNumber] = useState(1);
  const [artistNameLists, setArtistNameLists] = useState([]);
  const [artistsIsLoading, setArtistsIsLoading] = useState(true);
  const [moreArtistIsLoading, setMoreArtistIsLoading] = useState(true);
  const [allAlbums, setAllAlbums] = useState([]);
  const [allGenres, setAllGenres] = useState([]);
  const [years, setYears] = useState([]);
  const [albumsIsLoading, setAlbumsIsLoading] = useState(true);
  const [albumsPageNumber, setAlbumsPageNumber] = useState(1);
  const [albums, setAlbums] = useState([]);
  const [moreAlbumsIsLoading, setMoreAlbumsIsLoading] = useState(true);
  const [albumsLastPage, setAlbumsLastPage] = useState(null);
  const [genresList, setGenresList] = useState([]);
  const [genresIsLoading, setGenresIsLoading] = useState(true);
  const [genresPageNumber, setGenresPageNumber] = useState(1);
  const [genresLastPage, setGenresLastPage] = useState(null);
  const [moreGenresIsLoading, setMoreGenresIsLoading] = useState(false);
  const [songsList, setSongsList] = useState([]);
  const [songsIsLoading, setSongsIsLoading] = useState(true);
  const [songsPageNumber, setSongsPageNumber] = useState(1);
  const [songsLastPage, setSongsLastPage] = useState(null);
  const [moreSongsIsLoading, setMoreSongsIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [currentSong, setCurrentSong] = useState(null);
  const music = useSelector(selectMusic)[0];
  const [tempFilters, setTempFilters] = useState({});
  const [regionUrl, setRegionUrl] = useState(null);
  // To check whether the data has been fetched for the active tab or not.
  const [dataFetched, setDataFetched] = useState({
    events: false,
    photos: false,
    videos: false,
    carnivals: false,
    newsarticles: false,
    reviews: false,
    artists: false,
    albums: false,
    genres: false,
    music: false,
    all: false,
  });
  // To check whether the filtered data has been fetched for the active tab or not.
  const [filteredDataFetched, setFilteredDataFetched] = useState({
    events: false,
    photos: false,
    videos: false,
    carnivals: false,
    newsarticles: false,
    reviews: false,
    artists: false,
    albums: false,
    genres: false,
    music: false,
    all: false,
  });

  useEffect(()=>{
    ReactGA.send({
      hitType: "pageview",
      page: `/global-search`,
      title: "Global Search",
    });
  },[])

  // Update the current song
  useEffect(() => {
    if (!music?.isPlaying) {
      setCurrentSong(null);
    } else {
      setCurrentSong(music?.musicArgs);
    }
  }, [music]);

  const handleGetSongs = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_MUSIC_SONGS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: songsPageNumber,
          items: PER_PAGE_COUNT.MUSIC_NEW_RELEASES,
          orderDirection: "asc",
          search: search,
          ...(filters?.music?.album && {
            albums: filters?.music?.album,
          }),
          ...(filters?.music?.artist && { artists: filters?.music?.artist }),
          ...(filters?.music?.genre && { genres: filters?.music?.genre }),
          ...(filters?.music?.year && { year: filters?.music?.year }),
        },
      });
      if (res.status === 200) {
        if (res?.data?.data && res?.data?.data?.length !== 0) {
          setSongsLastPage(res?.data?.last_page);
          setTotalSongs(res?.data?.total);
          const songs = res?.data?.data.map((item) => {
            const genre = item?.album?.genres?.map(
              (i, index) =>
                `${i?.title}${
                  index !== item?.album?.genres?.length - 1 ? ", " : ""
                }`
            );
            return {
              ...item,
              subTitle: item?.album?.title,
              thumbnail: item?.album?.cover_image_url,
              footerText: genre[0],
              hlsSource: item?.media_url,
            };
          });
          if (songsPageNumber === 1 && songs) {
            setSongsList(songs);
          } else {
            if (songs) {
              setSongsList((prev) => [...prev, ...songs]);
            }
          }
        }
        setSongsIsLoading(false);
        if (moreSongsIsLoading) {
          setMoreSongsIsLoading(false);
        }
      }
    } catch (err) {
      if (moreSongsIsLoading) {
        setMoreSongsIsLoading(false);
      }
      setSongsList([]);
      setSongsIsLoading(false);
    }
  };

  const loadMoreSongs = () => {
    if (songsLastPage != null && songsPageNumber <= songsLastPage) {
      setMoreSongsIsLoading(true);
      setSongsPageNumber(songsPageNumber + 1);
    }
  };

  const handleGetGenres = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_MUSIC_GENRES, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: genresPageNumber,
          items: PER_PAGE_COUNT.MUSIC_NEW_RELEASES,
          orderDirection: "asc",
          search: search,
          ...(filters?.genres?.album && {
            albums: filters?.genres?.album,
          }),
        },
      });
      if (res.status === 200) {
        if (res?.data?.data && res?.data?.data?.length !== 0) {
          setGenresLastPage(res?.data?.last_page);
          setTotalGenres(res?.data?.total);
          if (genresPageNumber === 1) {
            setGenresList(res?.data?.data);
          } else {
            setGenresList((prev) => [...prev, ...res?.data?.data]);
          }
        }
        setGenresIsLoading(false);
        if (moreGenresIsLoading) {
          setMoreGenresIsLoading(false);
        }
      }
    } catch (err) {
      if (moreGenresIsLoading) {
        setMoreGenresIsLoading(false);
      }
      setGenresList([]);
      setGenresIsLoading(false);
    }
  };

  const loadMoreGenres = () => {
    if (genresLastPage != null && genresPageNumber <= genresLastPage) {
      setMoreGenresIsLoading(true);
      setGenresPageNumber(genresPageNumber + 1);
    }
  };

  const handleGetAlbums = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_MUSIC_ALL_ALBUMS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: albumsPageNumber,
          items: PER_PAGE_COUNT.CARNIVAL_MUSIC,
          orderDirection: "asc",
          search: search,
          ...(filters?.albums?.location && {
            countries: filters?.albums?.location,
          }),
          ...(filters?.albums?.artist && { artists: filters?.albums?.artist }),
          ...(filters?.albums?.genre && { genres: filters?.albums?.genre }),
          ...(filters?.albums?.year && { year: filters?.albums?.year }),
        },
      });
      if (res.status === 200) {
        setAlbumsLastPage(res?.data?.last_page);
        setTotalAlbums(res?.data?.total);
        if (albumsPageNumber === 1) {
          setAlbums(res?.data?.data);
        } else {
          setAlbums((prev) => [...prev, ...res?.data?.data]);
        }
        setAlbumsIsLoading(false);
        if (moreAlbumsIsLoading) {
          setMoreAlbumsIsLoading(false);
        }
      }
    } catch (err) {
      if (moreAlbumsIsLoading) {
        setMoreAlbumsIsLoading(false);
      }
      setAlbums([]);
      setAlbumsIsLoading(false);
    }
  };

  const loadMoreAlbums = () => {
    if (albumsLastPage != null && albumsPageNumber <= albumsLastPage) {
      setMoreAlbumsIsLoading(true);
      setAlbumsPageNumber(albumsPageNumber + 1);
    }
  };

  const handleGetArtists = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_MUSIC_ARTISTS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: artistsPageNumber,
          items: PER_PAGE_COUNT.MUSIC_ARTISTS_MORE,
          orderDirection: "asc",
          search: search,
          ...(filters?.artists?.genre && {
            genre: filters?.artists?.genre,
          }),
          ...(filters?.artists?.country && {
            country: filters?.artists?.country,
          }),
        },
      });
      if (res.status === 200) {
        if (res?.data?.data && res?.data?.data?.length !== 0) {
          setArtistsLastPage(res?.data?.last_page);
          setTotalArtists(res?.data?.total);
          if (artistsPageNumber === 1) {
            setArtistNameLists(res?.data?.data);
          } else {
            setArtistNameLists((prev) => [...prev, ...res?.data?.data]);
          }
        } else {
          setArtistNameLists([]);
          setArtistsLastPage(null);
        }
        if (moreArtistIsLoading) {
          setMoreArtistIsLoading(false);
        }
        setArtistsIsLoading(false);
      }
    } catch (err) {
      if (moreArtistIsLoading) {
        setMoreArtistIsLoading(false);
      }
      setArtistsIsLoading(false);
      setArtistNameLists([]);
    }
  };

  const updateArtistsPageNumber = () => {
    if (artistsLastPage != null && artistsPageNumber <= artistsLastPage) {
      setMoreArtistIsLoading(true);
      setArtistsPageNumber(artistsPageNumber + 1);
    }
  };

  const handleGetReviews = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ALL_REVIEWS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: ReviewsPageNumber,
          items: PER_PAGE_COUNT.REVIEWS_ITEM_COUNT,
          orderDirection: "asc",
          search: search,
          ...(filters?.reviews?.reviewType && {
            type: filters?.reviews?.reviewType,
          }),
          ...(filters?.reviews?.tag && {
            tags: filters?.reviews?.tag,
          }),
          ...(filters?.reviews?.category && {
            categories: filters?.reviews?.category,
          }),
          ...(filters?.reviews?.author && {
            authors: filters?.reviews?.author,
          }),
          ...(filters?.reviews?.startDate && {
            startDate: filters?.reviews?.startDate,
          }),
          ...(filters?.reviews?.endDate && {
            endDate: filters?.reviews?.endDate,
          }),
          ...(filters?.all?.startDate &&
            !filters?.reviews?.startDate && {
              startDate: filters?.all?.startDate,
            }),
          ...(filters?.all?.endDate &&
            !filters?.reviews?.endDate && {
              endDate: filters?.all?.endDate,
            }),
        },
      });
      if (res.status === 200 && res?.data) {
        setReviewsLastPage(res?.data?.reviews?.last_page);
        setTotalReviews(res?.data?.reviews?.total);
        if (ReviewsPageNumber === 1) {
          setReviews(res?.data?.reviews?.data);
        } else {
          setReviews((prev) => [...prev, ...res?.data?.reviews?.data]);
        }
        setReviewsIsLoading(false);
        if (moreReviewsIsLoading) {
          setMoreReviewsIsLoading(false);
        }
      }
    } catch (err) {
      setReviewsIsLoading(false);
      if (moreReviewsIsLoading) {
        setMoreReviewsIsLoading(false);
      }
    }
  };

  function updateReviewsPageNumber() {
    if (ReviewsLastPage != null && ReviewsPageNumber <= ReviewsLastPage) {
      setMoreReviewsIsLoading(true);
      setReviewsPageNumber(ReviewsPageNumber + 1);
    }
  }

  function updateNewsArticlesPageNumber() {
    if (
      newsArticlesLastPage != null &&
      newsArticlesPageNumber <= newsArticlesLastPage
    ) {
      setMoreNewsArticlesIsLoading(true);
      setNewsArticlesPageNumber(newsArticlesPageNumber + 1);
    }
  }

  const handleGetNewsArticles = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ALL_NEWSARTICLES, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: newsArticlesPageNumber,
          items: PER_PAGE_COUNT.NEWSARTICLES_ITEM_COUNT,
          orderDirection: "asc",
          search: search,
          ...(filters?.newsarticles?.tag && {
            tags: filters?.newsarticles?.tag,
          }),
          ...(filters?.newsarticles?.category && {
            categories: filters?.newsarticles?.category,
          }),
          ...(filters?.newsarticles?.author && {
            authors: filters?.newsarticles?.author,
          }),
          ...(filters?.all?.startDate &&
            !filters?.newsarticles?.startDate && {
              startDate: filters?.all?.startDate,
            }),
          ...(filters?.all?.endDate &&
            !filters?.newsarticles?.endDate && {
              endDate: filters?.all?.endDate,
            }),
          ...(filters?.newsarticles?.startDate && {
            startDate: filters?.newsarticles?.startDate,
          }),
          ...(filters?.newsarticles?.endDate && {
            endDate: filters?.newsarticles?.endDate,
          }),
        },
      });
      if (res.status === 200 && res?.data) {
        setNewsArticlesLastPage(res?.data?.news_articles?.last_page);
        setTotalNewsArticles(res?.data?.news_articles?.total);
        if (newsArticlesPageNumber === 1) {
          setNewsArticles(res?.data?.news_articles?.data);
        } else {
          setNewsArticles((prev) => [
            ...prev,
            ...res?.data?.news_articles?.data,
          ]);
        }
        setNewsArticlesLoading(false);
        if (moreNewsArticlesIsLoading) {
          setMoreNewsArticlesIsLoading(false);
        }
      }
    } catch (err) {
      setNewsArticlesLoading(false);
      if (moreNewsArticlesIsLoading) {
        setMoreNewsArticlesIsLoading(false);
      }
    }
  };

  function updateCarnivalsPageNumber() {
    if (carnivalsLastPage != null && carnivalsPageNumber <= carnivalsLastPage) {
      setMoreCarnivalsIsLoading(true);
      setCarnivalsPageNumber(carnivalsPageNumber + 1);
    }
  }

  const handleGetCarnivals = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_CARNIVALS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: carnivalsPageNumber,
          items: PER_PAGE_COUNT.ALL_CARNIVALS,
          orderDirection: "asc",
          search: search,
          ...(filters?.carnivals?.location && {
            locations: filters?.carnivals?.location,
          }),
          ...(filters?.carnivals?.region && {
            region: filters?.carnivals?.region,
          }),
          ...(filters?.carnivals?.startDate && {
            startDate: filters?.carnivals?.startDate,
          }),
          ...(filters?.carnivals?.endDate && {
            endDate: filters?.carnivals?.endDate,
          }),
          ...(filters?.carnivals?.region && {
            region: filters?.carnivals?.region,
          }),
          ...(filters?.all?.startDate &&
            !filters?.carnivals?.startDate && {
              startDate: filters?.all?.startDate,
            }),
          ...(filters?.all?.endDate &&
            !filters?.carnivals?.endDate && {
              endDate: filters?.all?.endDate,
            }),
        },
      });
      if (res.status === 200 && res?.data) {
        setCarnivalsLastPage(res?.data?.last_page);
        setTotalCarnivals(res?.data?.total);
        if (carnivalsPageNumber === 1) {
          setCarnivals(res?.data?.data);
        } else {
          setCarnivals((prev) => [...prev, ...res?.data?.data]);
        }
        if (moreCarnivalsIsLoading) {
          setMoreCarnivalsIsLoading(false);
        }
        setCarnivalsIsLoading(false);
      }
    } catch (err) {
      setCarnivalsIsLoading(false);
    }
  };

  const handleGetVideos = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ALL_VIDEOS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: videosPageNumber,
          items: PER_PAGE_COUNT.ALL_VIDEOS,
          orderDirection: "asc",
          search: search,
          ...(filters?.videos?.tag && {
            tags: filters?.videos?.tag,
          }),
          ...(filters?.videos?.category && {
            categories: filters?.videos?.category,
          }),
          ...(filters?.videos?.artist && {
            artists: filters?.videos?.artist,
          }),
          ...(filters?.videos?.startDate && {
            startDate: filters?.videos?.startDate,
          }),
          ...(filters?.videos?.endDate && {
            endDate: filters?.videos?.endDate,
          }),
          ...(filters?.all?.startDate &&
            !filters?.videos?.startDate && {
              startDate: filters?.all?.startDate,
            }),
          ...(filters?.all?.endDate &&
            !filters?.videos?.endDate && {
              endDate: filters?.all?.endDate,
            }),
        },
      });
      if (res.status === 200) {
        setVideosLastPage(res?.data?.last_page);
        setTotalVideos(res?.data?.total);
        if (videosPageNumber === 1) {
          setVideos(res?.data?.data);
        } else {
          setVideos((prev) => [...prev, ...res?.data?.data]);
        }
        if (moreVideosIsLoading) {
          setMoreVideosIsLoading(false);
        }
        setVideosIsLoading(false);
      }
    } catch (err) {
      setVideosIsLoading(false);
    }
  };

  function updateVideoPageNumber() {
    if (videosLastPage != null && videosPageNumber <= videosLastPage) {
      setMoreVideosIsLoading(true);
      setVideosPageNumber(videosPageNumber + 1);
    }
  }

  const getAllAuthor = async () => {
    try {
      const res = await axios.get(authorUrl, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.ALL_DATA,
        },
      });
      if (res.status === 200) {
        if (res?.data?.authors?.data?.length !== 0) {
          setAuthorsList(res?.data?.authors?.data);
        }
      }
    } catch (err) {
      setAuthorsList([]);
    }
  };

  useEffect(() => {
    if (authorUrl) {
      getAllAuthor();
    }
  }, [authorUrl]);

  useEffect(() => {
    if (url) {
      setCategoryList([]);
      handleGetData();
    }
  }, [url]);

  const getCountry = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_LOCATIONS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.ALL_DATA,
        },
      });
      if (res.status === 200) {
        if (res?.data?.data && res?.data?.data?.length !== 0) {
          setCountryList(res?.data?.data);
        }
      }
    } catch (err) {
      setCountryList([]);
    }
  };

  const getRegion = async () => {
    try {
      const res = await axios.get(regionUrl, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.ALL_DATA,
        },
      });
      if (res.status === 200) {
        if (res?.data?.data && res?.data?.data?.length !== 0) {
          setRegionList(res?.data?.data);
        }
      }
    } catch (err) {
      setRegionList([]);
    }
  };

  const getAllPromoters = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_EVENTS_PROMOTERS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.EVENT_ALL_CATEGORIES_PROMOTERS,
        },
      });
      if (res.status === 200) {
        setAllPromoters(res?.data?.promoters?.data);
      }
    } catch (err) {
      setAllPromoters([]);
    }
  };

  // Get all the category of the selected type
  const handleGetData = async () => {
    try {
      const res = await axios.get(url, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.ALL_DATA,
        },
      });
      if (res.status === 200) {
        if (res?.data) {
          if (activeTab === "newsletters") {
            setCategoryList(res?.data?.newsletter_categories?.data);
          }
          if (activeTab === "events") {
            setCategoryList(res?.data?.event_categories?.data);
          }
          if (activeTab === "reviews") {
            setCategoryList(res?.data?.review_categories?.data);
          }
          if (activeTab === "newsarticles") {
            setCategoryList(res?.data?.categories?.data);
          }
          if (activeTab === "videos") {
            setCategoryList(res?.data?.categories?.data);
          }
        }
      }
    } catch (err) {
      setCategoryList([]);
    }
  };

  const getTags = async () => {
    const tempUrl =
      tagType === "photos" ? SERVER_URL.GET_GALLERY_TAGS : SERVER_URL.GET_TAGS;
    try {
      const res = await axios.get(tempUrl, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.ALL_DATA,
          type: tagType,
        },
      });
      if (res.status === 200) {
        if (res?.data && res?.data?.data && res?.data?.data?.length !== 0) {
          setTagsList(res?.data?.data);
        } else {
          setTagsList([]);
        }
      }
    } catch (err) {
      setTagsList([]);
    }
  };

  const getArtist = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_MUSIC_ARTISTS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.ALL_DATA,
        },
      });
      if (res.status === 200) {
        if (res?.data && res?.data?.data && res?.data?.data?.length !== 0) {
          setArtists(res?.data?.data);
        }
      }
    } catch (err) {
      setArtists([]);
    }
  };

  const getPhotographers = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ALL_PHOTOGRAPHERS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.ALL_DATA,
        },
      });
      if (res.status === 200) {
        if (
          res?.data?.photographers &&
          res?.data?.photographers?.length !== 0
        ) {
          setPhotographers(res?.data?.photographers);
        }
      }
    } catch (err) {
      setPhotographers([]);
    }
  };

  const getAlbums = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_MUSIC_ALL_ALBUMS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.ALL_DATA,
        },
      });
      if (res.status === 200) {
        if (res?.data?.data && res?.data?.data?.length !== 0) {
          setAllAlbums(res?.data?.data);
        }
      }
    } catch (err) {
      setAllAlbums([]);
    }
  };

  const getYears = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_MUSIC_YEARS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          per_page: PER_PAGE_COUNT.ALL_DATA,
        },
      });
      if (res.status === 200) {
        if (res?.data?.data && res?.data?.data?.length !== 0) {
          setYears(res?.data?.data);
        }
      }
    } catch (err) {
      setYears([]);
    }
  };

  const getGenres = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_MUSIC_GENRES, {
        headers: {
          Accept: "application/json",
        },
        params: {
          per_page: PER_PAGE_COUNT.ALL_DATA,
        },
      });
      if (res.status === 200) {
        if (res?.data?.data && res?.data?.data?.length !== 0) {
          setAllGenres(res?.data?.data);
        }
      }
    } catch (err) {
      setAllGenres([]);
    }
  };

  useEffect(() => {
    if (regionUrl) {
      setRegionList([]);
      getRegion();
    }
  }, [regionUrl]);

  useEffect(() => {
    if (tagType) {
      setTagsList([]);
      getTags();
    }
  }, [tagType]);

  useEffect(() => {
    if (activeTab) {
      if (activeTab === "carnivals") {
        setTagType("carnival");
        setUrl(null);
        setAuthorUrl(null);
        setRegionUrl(SERVER_URL.GET_CARNIVAL_REGIONS);
      }
      if (activeTab === "newsletters") {
        setTagType("newsletter");
        setUrl(SERVER_URL.GET_CATEGORIES_NEWSLETTERS);
        setAuthorUrl(SERVER_URL.GET_AUTHORS_NEWSLETTERS);
        setRegionUrl(null);
      }
      if (activeTab === "events") {
        setTagType("event");
        setUrl(SERVER_URL.GET_EVENTS_CATEGORIES);
        setAuthorUrl(null);
        setRegionUrl(SERVER_URL.GET_EVENT_REGIONS);
      }
      if (activeTab === "reviews") {
        setTagType("review");
        setUrl(SERVER_URL.GET_CATEGORIES_REVIEWS);
        setAuthorUrl(SERVER_URL.GET_AUTHORS);
        setRegionUrl(null);
      }
      if (activeTab === "newsarticles") {
        setTagType("news_article");
        setUrl(SERVER_URL.GET_CATEGORIES_NEWSARTICLES);
        setAuthorUrl(SERVER_URL.GET_AUTHORS_NEWSARTICLES);
        setRegionUrl(null);
      }
      if (activeTab === "videos") {
        setTagType("video");
        setUrl(SERVER_URL.GET_VIDEO_CATEGORIES);
        setAuthorUrl(null);
        setRegionUrl(null);
      }
      if (activeTab === "photos") {
        setTagType("photos");
        setUrl(null);
        setAuthorUrl(null);
        setRegionUrl(SERVER_URL.GET_GALLERY_REGIONS);
      }
    }
  }, [activeTab]);

  useEffect(() => {
    getCountry();
    getAllPromoters();
    getPhotographers();
    getArtist();
    getAlbums();
    getYears();
    getGenres();
  }, []);

  // This function will be used to reset the filters as well as remove filter params from the search url.
  const handleReset = async () => {
    const searchParamsToBeRemoved = [
      "location",
      "tag",
      "category",
      "promoter",
      "time",
      "region",
      "startDate",
      "endDate",
      "carnivals",
      "photographer",
      "genre",
      "review_type",
      "album",
      "artist_name",
      "year",
      "country",
    ];
    searchParamsToBeRemoved.map((item) => {
      if (searchParams.has(item)) {
        searchParams.delete(item);
        setSearchParams(searchParams);
        delete params[activeTab][item];
      }
    });
    setFilteredDataFetched((prev) => ({ ...prev, [activeTab]: false }));
    setDataFetched((prev) => ({ ...prev, [activeTab]: false }));
    handleSetFilters({});
    setOpen(false);
  };

  const getEventsData = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_EVENTS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: eventsPageNumber,
          items: PER_PAGE_COUNT.EVENTS,
          orderDirection: "asc",
          search: search,
          ...(filters?.events?.location && {
            locations: filters?.events?.location,
          }),
          ...(filters?.events?.region && {
            region: filters?.events?.region,
          }),
          ...(filters?.events?.tag && {
            tags: filters?.events?.tag,
          }),
          ...(filters?.events?.category && {
            categories: filters?.events?.category,
          }),
          ...(filters?.events?.promoter && {
            promoters: filters?.events?.promoter,
          }),
          ...(filters?.events?.time && {
            time: filters?.events?.time,
          }),
          ...(filters?.events?.startDate && {
            startDate: filters?.events?.startDate,
          }),
          ...(filters?.events?.endDate && {
            endDate: filters?.events?.endDate,
          }),
          ...(filters?.all?.startDate &&
            !filters?.events?.startDate && {
              startDate: filters?.all?.startDate,
            }),
          ...(filters?.all?.endDate &&
            !filters?.events?.endDate && {
              endDate: filters?.all?.endDate,
            }),
        },
      });
      if (res.status === 200) {
        if (
          res?.data &&
          res?.data?.length !== 0 &&
          res?.data?.data?.length !== 0
        ) {
          setEventsLastPage(res?.data?.last_page);
          setTotalEvents(res?.data?.total);
          if (eventsPageNumber === 1) {
            setEvents(res?.data?.data);
          } else {
            setEvents((prev) => [...prev, ...res?.data?.data]);
          }
        } else {
          setEvents([]);
          setEventsLastPage(null);
        }
        setEventsIsLoading(false);
        if (moreEventsIsLoading) {
          setMoreEventsIsLoading(false);
        }
      } else {
        setEvents([]);
        setEventsLastPage(null);
        setEventsIsLoading(false);
        if (moreEventsIsLoading) {
          setMoreEventsIsLoading(false);
        }
      }
    } catch (err) {
      setEvents([]);
      setEventsIsLoading(false);
      if (moreEventsIsLoading) {
        setMoreEventsIsLoading(false);
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearchResults();
    }
  };

  const handleSearchParam = (key, value) => {
    if (key === "tab") {
      setParams((prevParams) => {
        const currentTabState = prevParams[value];
        const updatedTabState = {
          ...currentTabState,
          [key]: value,
        };
        return {
          ...prevParams,
          [value]: updatedTabState,
        };
      });
    } else if (key === "search") {
      tabs?.map((item) => {
        setParams((prevParams) => {
          const currentTabState = prevParams[item?.slug];
          const updatedTabState = {
            ...currentTabState,
            [key]: value,
          };
          return {
            ...prevParams,
            [item?.slug]: updatedTabState,
          };
        });
      });
    } else {
      setParams((prevParams) => {
        const currentTabState = prevParams[activeTab];
        const updatedTabState = {
          ...currentTabState,
          [key]: value,
        };
        return {
          ...prevParams,
          [activeTab]: updatedTabState,
        };
      });
    }
  };

  useEffect(() => {
    if (searchParams.size !== 0) {
      let param = {};
      if (searchParams?.getAll("location")?.length !== 0) {
        param = {
          ...param,
          location: searchParams?.getAll("location"),
        };
      }
      if (searchParams?.get("region")) {
        param = {
          ...param,
          region: searchParams?.get("region"),
        };
      }
      if (searchParams?.getAll("photographer")?.length !== 0) {
        param = {
          ...param,
          photographer: searchParams?.getAll("photographer"),
        };
      }

      if (searchParams?.getAll("genre")?.length !== 0) {
        param = {
          ...param,
          genre: searchParams?.getAll("genre"),
        };
      }
      if (searchParams?.get("review_type")) {
        param = {
          ...param,
          review_type: searchParams?.get("review_type"),
        };
      }
      if (searchParams?.getAll("album")?.length !== 0) {
        param = {
          ...param,
          album: searchParams?.getAll("album"),
        };
      }
      if (searchParams?.getAll("artist_name")?.length !== 0) {
        param = {
          ...param,
          artist_name: searchParams?.getAll("artist_name"),
        };
      }
      if (searchParams?.getAll("category").length !== 0) {
        param = {
          ...param,
          category: searchParams?.getAll("category"),
        };
      }
      if (searchParams?.getAll("year").length !== 0) {
        param = {
          ...param,
          year: searchParams?.getAll("year"),
        };
      }
      if (searchParams?.getAll("time").length !== 0) {
        param = {
          ...param,
          time: searchParams?.getAll("time"),
        };
      }
      if (searchParams?.getAll("promoter").length !== 0) {
        param = {
          ...param,
          promoter: searchParams?.getAll("promoter"),
        };
      }
      if (searchParams?.getAll("tag")?.length !== 0) {
        param = {
          ...param,
          tag: searchParams?.getAll("tag"),
        };
      }
      if (searchParams?.get("startDate")) {
        param = {
          ...param,
          startDate: searchParams?.getAll("startDate"),
        };
      }
      if (searchParams?.get("endDate")) {
        param = {
          ...param,
          endDate: searchParams?.getAll("endDate"),
        };
      }
      if (searchParams?.get("country")) {
        param = {
          ...param,
          country: searchParams?.getAll("country"),
        };
      }
      if (param && Object.keys(param).length > 0) {
        setTempFilters(param);
      }
    }
  }, []);

  useEffect(() => {
    if (
      Object.keys(tempFilters)?.length !== 0 &&
      activeTab &&
      Object.keys(filters[activeTab])?.length === 0
    ) {
      handleSearch(tempFilters);
      setTempFilters({});
    }
  }, [tempFilters, activeTab]);

  useEffect(() => {
    if (searchParams.size !== 0 && searchParams?.get("tab")) {
      handleChange(
        null,
        tabs.filter((p) => p.slug === searchParams.get("tab"))[0]?.index
      );
    } else {
      setActiveTab("all");
    }
    if (searchParams.size !== 0 && searchParams?.get("search")) {
      if (search !== searchParams?.get("search")) {
        handleSearchResultsParam(searchParams?.get("search"));
        setSearchingStarted(true);
      }
    }
  }, []);

  useEffect(() => {
    if (
      params &&
      (Object.keys(params?.all).length > 0 ||
        Object.keys(params?.albums).length > 0 ||
        Object.keys(params?.artists).length > 0 ||
        Object.keys(params?.carnivals).length > 0 ||
        Object.keys(params?.events).length > 0 ||
        Object.keys(params?.genres).length > 0 ||
        Object.keys(params?.music).length > 0 ||
        Object.keys(params?.newsarticles).length > 0 ||
        Object.keys(params?.photos).length > 0 ||
        Object.keys(params?.reviews).length > 0 ||
        Object.keys(params?.videos).length > 0) &&
      activeTab
    ) {
      setSearchParams(params[activeTab]);
    }
  }, [params]);

  function handleSetFilters(filters) {
    setFilters((prevParams) => {
      const updatedTabState = filters;
      return {
        ...prevParams,
        [activeTab]: updatedTabState,
      };
    });
  }

  const handleGetGalleries = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ALL_GALLERIES, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.GALLERIES_HOME,
          type: "most_recent",
          search: search,
          orderDirection: "asc",
          ...(filters?.photos?.location && {
            locations: filters?.photos?.location,
          }),
          ...(filters?.photos?.region && {
            region: filters?.photos?.region,
          }),
          ...(filters?.photos?.startDate && {
            startDate: filters?.photos?.startDate,
          }),
          ...(filters?.photos?.endDate && {
            endDate: filters?.photos?.endDate,
          }),
          ...(filters?.all?.startDate &&
            !filters?.photos?.startDate && {
              startDate: filters?.all?.startDate,
            }),
          ...(filters?.all?.startDate &&
            !filters?.photos?.endDate && {
              endDate: filters?.all?.endDate,
            }),
          ...(filters?.photos?.photographer && {
            photographer: filters?.photos?.photographer,
          }),
        },
      });
      if (res.status === 200) {
        if (
          res?.data &&
          res?.data?.length !== 0 &&
          res?.data?.galleries?.data?.length !== 0
        ) {
          setGalleriesLastPage(res?.data?.galleries?.last_page);
          setTotalGalleries(res?.data?.galleries?.total);
          if (galleriesPageNumber === 1) {
            setGalleries(res?.data?.galleries?.data);
          } else {
            setGalleries((prev) => [...prev, ...res?.data?.galleries?.data]);
          }
        } else {
          setGalleries([]);
          setGalleriesLastPage(null);
        }
        setGalleriesIsLoading(false);
        if (moreGalleriesIsLoading) {
          setMoreGalleriesIsLoading(false);
        }
      } else {
        setGalleries([]);
        setGalleriesLastPage(null);
        setGalleriesIsLoading(false);
        if (moreGalleriesIsLoading) {
          setMoreGalleriesIsLoading(false);
        }
      }
    } catch (err) {
      setGalleries([]);
      setGalleriesLastPage(null);
      setGalleriesIsLoading(false);
      if (moreGalleriesIsLoading) {
        setMoreGalleriesIsLoading(false);
      }
    }
  };

  function updateGalleryPageNumber() {
    if (galleriesLastPage != null && galleriesPageNumber <= galleriesLastPage) {
      setMoreGalleriesIsLoading(true);
      setGalleriesPageNumber(galleriesPageNumber + 1);
    }
  }

  const handleSearch = (item) => {
    setFilteredDataFetched((prev) => ({ ...prev, [activeTab]: false }));
    setOpen(false);
    handleSetFilters(item);
    Object.entries(item).map((i) => {
      handleSearchParam(i[0], i[1]);
    });
  };

  const handleChange = (event, newValue) => {
    setValue(tabs[newValue].index);
    setActiveTab(tabs[newValue].slug);
    handleSearchParam("tab", tabs[newValue].slug);
  };

  useEffect(() => {
    if (
      search !== "" &&
      (activeTab === "events" || activeTab === "all") &&
      eventsPageNumber !== 1
    ) {
      getEventsData();
    }
  }, [eventsPageNumber]);

  useEffect(() => {
    if (
      search !== "" &&
      (activeTab === "music" || activeTab === "all") &&
      songsPageNumber !== 1
    ) {
      handleGetSongs();
    }
  }, [songsPageNumber]);

  useEffect(() => {
    if (
      search !== "" &&
      (activeTab === "genres" || activeTab === "all") &&
      genresPageNumber !== 1
    ) {
      handleGetGenres();
    }
  }, [genresPageNumber]);

  useEffect(() => {
    if (
      search !== "" &&
      (activeTab === "albums" || activeTab === "all") &&
      albumsPageNumber !== 1
    ) {
      handleGetAlbums();
    }
  }, [albumsPageNumber]);

  useEffect(() => {
    if (
      search !== "" &&
      (activeTab === "artists" || activeTab === "all") &&
      artistsPageNumber !== 1
    ) {
      handleGetArtists();
    }
  }, [artistsPageNumber]);

  useEffect(() => {
    if (
      search !== "" &&
      (activeTab === "reviews" || activeTab === "all") &&
      ReviewsPageNumber !== 1
    ) {
      handleGetReviews();
    }
  }, [ReviewsPageNumber]);

  useEffect(() => {
    if (
      search !== "" &&
      (activeTab === "carnivals" || activeTab === "all") &&
      carnivalsPageNumber !== 1
    ) {
      handleGetCarnivals();
    }
  }, [carnivalsPageNumber]);

  useEffect(() => {
    if (
      search !== "" &&
      (activeTab === "newsarticles" || activeTab === "all") &&
      newsArticlesPageNumber !== 1
    ) {
      handleGetNewsArticles();
    }
  }, [newsArticlesPageNumber]);

  useEffect(() => {
    if (
      search !== "" &&
      (activeTab === "photos" || activeTab === "all") &&
      galleriesPageNumber !== 1
    ) {
      handleGetGalleries();
    }
  }, [galleriesPageNumber]);

  useEffect(() => {
    if (
      search !== "" &&
      (activeTab === "videos" || activeTab === "all") &&
      videosPageNumber !== 1
    ) {
      handleGetVideos();
    }
  }, [videosPageNumber]);

  useEffect(() => {
    if (search === "") {
      setSearchingStarted(false);
    }
  }, [search]);

  useEffect(() => {
    if (searchParams.size !== 0 && searchParams.has("search")) {
      setSearchingStarted(true);
    }
  }, []);

  // This fetchData will fetch the data on the basis of data is fetched earlier or not.
  const fetchData = async () => {
    if (
      (activeTab === "events" || activeTab === "all") &&
      !dataFetched.events &&
      !filteredDataFetched.events
    ) {
      setEventsPageNumber(1);
      setEvents([]);
      setEventsIsLoading(true);
      await getEventsData();
      setDataFetched((prevState) => ({ ...prevState, events: true }));
    }
    if (
      (activeTab === "photos" || activeTab === "all") &&
      !dataFetched.photos &&
      !filteredDataFetched.photos
    ) {
      setGalleriesPageNumber(1);
      setGalleries([]);
      setGalleriesIsLoading(true);
      await handleGetGalleries();
      setDataFetched((prevState) => ({ ...prevState, photos: true }));
    }
    if (
      (activeTab === "videos" || activeTab === "all") &&
      !dataFetched.videos &&
      !filteredDataFetched.videos
    ) {
      setVideosPageNumber(1);
      setVideos([]);
      setVideosIsLoading(true);
      await handleGetVideos();
      setDataFetched((prevState) => ({ ...prevState, videos: true }));
    }
    if (
      (activeTab === "carnivals" || activeTab === "all") &&
      !dataFetched.carnivals &&
      !filteredDataFetched.carnivals
    ) {
      setCarnivalsPageNumber(1);
      setCarnivals([]);
      setCarnivalsIsLoading(true);
      await handleGetCarnivals();
      setDataFetched((prevState) => ({ ...prevState, carnivals: true }));
    }
    if (
      (activeTab === "newsarticles" || activeTab === "all") &&
      !dataFetched.newsarticles &&
      !filteredDataFetched.newsarticles
    ) {
      setNewsArticlesPageNumber(1);
      setNewsArticles([]);
      setNewsArticlesLoading(true);
      await handleGetNewsArticles();
      setDataFetched((prevState) => ({ ...prevState, newsarticles: true }));
    }
    if (
      (activeTab === "reviews" || activeTab === "all") &&
      !dataFetched.reviews &&
      !filteredDataFetched.reviews
    ) {
      setReviewsPageNumber(1);
      setReviews([]);
      setReviewsIsLoading(true);
      await handleGetReviews();
      setDataFetched((prevState) => ({ ...prevState, reviews: true }));
    }
    if (
      (activeTab === "artists" || activeTab === "all") &&
      !dataFetched.artists &&
      !filteredDataFetched.artists
    ) {
      setArtistsPageNumber(1);
      setArtists([]);
      setArtistsIsLoading(true);
      await handleGetArtists();
      setDataFetched((prevState) => ({ ...prevState, artists: true }));
    }
    if (
      (activeTab === "albums" || activeTab === "all") &&
      !dataFetched.albums &&
      !filteredDataFetched.albums
    ) {
      setAlbumsPageNumber(1);
      setAlbums([]);
      setAlbumsIsLoading(true);
      await handleGetAlbums();
      setDataFetched((prevState) => ({ ...prevState, albums: true }));
    }
    if (
      (activeTab === "genres" || activeTab === "all") &&
      !dataFetched.genres &&
      !filteredDataFetched.genres
    ) {
      setGenresPageNumber(1);
      setGenresList([]);
      setGenresIsLoading(true);
      await handleGetGenres();
      setDataFetched((prevState) => ({ ...prevState, genres: true }));
    }
    if (
      (activeTab === "music" || activeTab === "all") &&
      !dataFetched.music &&
      !filteredDataFetched.music
    ) {
      setSongsPageNumber(1);
      setSongsList([]);
      setSongsIsLoading(true);
      await handleGetSongs();
      setDataFetched((prevState) => ({ ...prevState, music: true }));
    }
    if (activeTab === "all") {
      setDataFetched({
        events: true,
        photos: true,
        videos: true,
        carnivals: true,
        newsarticles: true,
        reviews: true,
        artists: true,
        albums: true,
        genres: true,
        music: true,
        all: true,
      });
    }
  };

  // This useEffect will be responsible to fetch data on first load, when filters are applied, and when we reset the filters.
  useEffect(() => {
    if (search !== "") {
      if (searchParams.size !== 0) {
        if (searchParams.size === 1 && searchParams.has("search")) {
          fetchData();
        } else if (
          searchParams.size === 2 &&
          searchParams.has("search") &&
          searchParams.has("tab")
        ) {
          fetchData();
        } else {
          if (Object.keys(filters?.events)?.length !== 0) {
            if (!filteredDataFetched.events) {
              setEventsPageNumber(1);
              setEvents([]);
              setEventsIsLoading(true);
              if (activeTab === "events" || activeTab === "all") {
                getEventsData();
              }
              setFilteredDataFetched((prev) => ({ ...prev, events: true }));
            }
          }
          if (Object.keys(filters?.photos)?.length !== 0) {
            if (!filteredDataFetched.photos) {
              setGalleriesPageNumber(1);
              setGalleriesIsLoading(true);
              setGalleries([]);
              if (activeTab === "photos" || activeTab === "all") {
                handleGetGalleries();
              }
              if (dataFetched.photos) {
                setDataFetched((prevState) => ({
                  ...prevState,
                  photos: false,
                }));
              }
            }
            setFilteredDataFetched((prev) => ({ ...prev, photos: true }));
          }
          if (Object.keys(filters?.videos)?.length !== 0) {
            if (!filteredDataFetched.videos) {
              setVideosPageNumber(1);
              setVideosIsLoading(true);
              setVideos([]);
              if (activeTab === "videos" || activeTab === "all") {
                handleGetVideos();
              }
              if (dataFetched.videos) {
                setDataFetched((prevState) => ({
                  ...prevState,
                  videos: false,
                }));
              }
              setFilteredDataFetched((prev) => ({ ...prev, videos: true }));
            }
          }
          if (Object.keys(filters?.carnivals)?.length !== 0) {
            if (!filteredDataFetched.carnivals) {
              setCarnivalsPageNumber(1);
              setCarnivalsIsLoading(true);
              setCarnivals([]);
              if (activeTab === "carnivals" || activeTab === "all") {
                handleGetCarnivals();
              }
              if (dataFetched.carnivals) {
                setDataFetched((prevState) => ({
                  ...prevState,
                  carnivals: false,
                }));
              }
              setFilteredDataFetched((prev) => ({ ...prev, carnivals: true }));
            }
          }
          if (Object.keys(filters?.newsarticles)?.length !== 0) {
            if (!filteredDataFetched.newsarticles) {
              setNewsArticlesPageNumber(1);
              setNewsArticlesLoading(true);
              setNewsArticles([]);
              if (activeTab === "newsarticles" || activeTab === "all") {
                handleGetNewsArticles();
              }
              if (dataFetched.newsarticles) {
                setDataFetched((prevState) => ({
                  ...prevState,
                  newsarticles: false,
                }));
              }
              setFilteredDataFetched((prev) => ({
                ...prev,
                newsarticles: true,
              }));
            }
          }
          if (Object.keys(filters?.reviews)?.length !== 0) {
            if (!filteredDataFetched.reviews) {
              setReviewsPageNumber(1);
              setReviewsIsLoading(true);
              setReviews([]);
              if (activeTab === "reviews" || activeTab === "all") {
                handleGetReviews();
              }
              if (dataFetched.reviews) {
                setDataFetched((prevState) => ({
                  ...prevState,
                  reviews: false,
                }));
              }
              setFilteredDataFetched((prev) => ({ ...prev, reviews: true }));
            }
          }
          if (Object.keys(filters?.artists)?.length !== 0) {
            if (!filteredDataFetched.artists) {
              setArtistsPageNumber(1);
              setArtistsIsLoading(true);
              setArtistNameLists([]);
              if (activeTab === "artists" || activeTab === "all") {
                handleGetArtists();
              }
              if (dataFetched.artists) {
                setDataFetched((prevState) => ({
                  ...prevState,
                  artists: false,
                }));
              }
              setFilteredDataFetched((prev) => ({ ...prev, artists: true }));
            }
          }
          if (Object.keys(filters?.albums)?.length !== 0) {
            if (!filteredDataFetched.albums) {
              setAlbumsPageNumber(1);
              setAlbumsIsLoading(true);
              setAlbums([]);
              if (activeTab === "albums" || activeTab === "all") {
                handleGetAlbums();
              }
              if (dataFetched.albums) {
                setDataFetched((prevState) => ({
                  ...prevState,
                  albums: false,
                }));
              }
              setFilteredDataFetched((prev) => ({ ...prev, albums: true }));
            }
          }
          if (Object.keys(filters?.genres)?.length !== 0) {
            if (!filteredDataFetched.genres) {
              setGenresPageNumber(1);
              setGenresIsLoading(true);
              setGenresList([]);
              if (activeTab === "genres" || activeTab === "all") {
                handleGetGenres();
              }
              if (dataFetched.genres) {
                setDataFetched((prevState) => ({
                  ...prevState,
                  genres: false,
                }));
              }
              setFilteredDataFetched((prev) => ({ ...prev, genres: true }));
            }
          }
          if (Object.keys(filters?.music)?.length !== 0) {
            if (!filteredDataFetched.music) {
              setSongsPageNumber(1);
              setSongsIsLoading(true);
              setSongsList([]);
              if (activeTab === "music" || activeTab === "all") {
                handleGetSongs();
              }
              if (dataFetched.music) {
                setDataFetched((prevState) => ({ ...prevState, music: false }));
              }
              setFilteredDataFetched((prev) => ({ ...prev, music: true }));
            }
          }
        }
      } else {
        fetchData();
      }
    }
  }, [filters, activeTab, searchParams]);
  // In the above useEffect, Because of searchParams, api is calling two times. But if we remove it, then there is a problem occuring with all tab as it is unable to call other api. This problem will be resolved when we optimize the filters code and then we will remove the search params passed in the useEffect.

  const loadMoreEvents = () => {
    if (eventsLastPage != null && eventsPageNumber <= eventsLastPage) {
      setMoreEventsIsLoading(true);
      setEventsPageNumber(eventsPageNumber + 1);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setPageTitle("Global Search");
  }, []);

  function TabPanel({ children, value, index, ...props }) {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...props}
      >
        {value === index && (
          <div className={GlobalSearchCss.tabPanel}>{children}</div>
        )}
      </div>
    );
  }

  const StyledTabs = styled((props) => (
    <Tabs
      {...props}
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
    />
  ))({
    "& .MuiTabs-indicator": {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
    },
    "& .MuiTabs-indicatorSpan": {
      maxWidth: "98%",
      width: "100%",
      backgroundColor: "#FF0000",
    },
    "& .MuiSvgIcon-root": {
      color: "#fff",
      fontSize: 30,
    },
  });

  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: "none",
      fontWeight: "700",
      fontSize: FontSizes.subTitle,
      fontFamily: "Inter-Bold",
      marginRight: theme.spacing(1),
      color: "#fff",
      minWidth: "15%",
      "&.Mui-selected": {
        color: "#fff",
      },
      "&.Mui-focusVisible": {
        backgroundColor: "rgba(255, 255, 255, 0.32)",
      },
    })
  );

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleSearchResultsParam = (item) => {
    setSearch(item);
    handleSearchParam("search", item);
  };

  const handleDownload = async (song) => {
    if (!song?.media_url) {
      Swal.fire({
        title: "Unable to download the song",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
      return;
    }
  
    try {
      const response = await fetch(song.media_url);
      if (!response.ok) {
        Swal.fire({
          title: "Unable to download the song",
          icon: "error",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        });
      }
  
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      const fileExtension = song?.media_url?.split(".").pop(); // Assuming the extension is present in the URL
      const fileName =
        song?.title?.replace(/\s+/g, "-").toLowerCase() + "." + fileExtension;
      link.download = fileName;
      ReactGA.event({
        category: "Download",
        action: "Download button clicked",
        label: fileName,
      });
      ReactGA.event({
        category: "Download",
        action: `${fileName} is downloaded`,
        label: fileName,
      });
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      Swal.fire({
        title: "Unable to download the song",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  }; 

  const handleSearchResults = async () => {
    if (search !== "") {
      setEventsIsLoading(true);
      setGalleriesIsLoading(true);
      setVideosIsLoading(true);
      setSearchingStarted(true);
      setCarnivalsIsLoading(true);
      setNewsArticlesLoading(true);
      setReviewsIsLoading(true);
      setArtistsIsLoading(true);
      setAlbumsIsLoading(true);
      setGenresIsLoading(true);
      setSongsIsLoading(true);
      handleSearchParam("search", search);
      if (activeTab === "events" || activeTab === "all") {
        setEventsPageNumber(1);
        setEvents([]);
        await getEventsData();
        setDataFetched((prevState) => ({ ...prevState, events: true }));
      }
      if (activeTab === "photos" || activeTab === "all") {
        setGalleriesPageNumber(1);
        setGalleries([]);
        await handleGetGalleries();
        setDataFetched((prevState) => ({ ...prevState, photos: true }));
      }
      if (activeTab === "videos" || activeTab === "all") {
        setVideosPageNumber(1);
        setVideos([]);
        await handleGetVideos();
        setDataFetched((prevState) => ({ ...prevState, videos: true }));
      }
      if (activeTab === "carnivals" || activeTab === "all") {
        setCarnivalsPageNumber(1);
        setCarnivals([]);
        await handleGetCarnivals();
        setDataFetched((prevState) => ({ ...prevState, carnivals: true }));
      }
      if (activeTab === "newsarticles" || activeTab === "all") {
        setNewsArticlesPageNumber(1);
        setNewsArticles([]);
        await handleGetNewsArticles();
        setDataFetched((prevState) => ({ ...prevState, newsarticles: true }));
      }
      if (activeTab === "reviews" || activeTab === "all") {
        setReviewsPageNumber(1);
        setReviews([]);
        await handleGetReviews();
        setDataFetched((prevState) => ({ ...prevState, reviews: true }));
      }
      if (activeTab === "artists" || activeTab === "all") {
        setArtistsPageNumber(1);
        setArtistNameLists([]);
        await handleGetArtists();
        setDataFetched((prevState) => ({ ...prevState, artists: true }));
      }
      if (activeTab === "albums" || activeTab === "all") {
        setAlbumsPageNumber(1);
        setAlbums([]);
        await handleGetAlbums();
        setDataFetched((prevState) => ({ ...prevState, albums: true }));
      }
      if (activeTab === "genres" || activeTab === "all") {
        setGenresPageNumber(1);
        setGenresList([]);
        await handleGetGenres();
        setDataFetched((prevState) => ({ ...prevState, genres: true }));
      }
      if (activeTab === "music" || activeTab === "all") {
        setSongsPageNumber(1);
        setSongsList([]);
        await handleGetSongs();
        setDataFetched((prevState) => ({ ...prevState, music: true }));
      }
      if (activeTab === "all") {
        setDataFetched({
          events: true,
          photos: true,
          videos: true,
          carnivals: true,
          newsarticles: true,
          reviews: true,
          artists: true,
          albums: true,
          genres: true,
          music: true,
          all: true,
        });
      }
    }
  };

  function handleAddToQueue(data) {
    ReactGA.event({
      category: "Queue",
      action: `Add to queue button clicked`,
      label: `Songs added to the queue`,
    });
    if (Array.isArray(data)) {
      data.map((item) => {
        const genre = item?.album?.genres?.map(
          (i, index) =>
            `${i?.title}${
              index !== item?.album?.genres?.length - 1 ? ", " : ""
            }`
        );
        const d = {
          ...item,
          subTitle: item?.album?.title,
          thumbnail: item?.album?.cover_image_url,
          footerText: genre[0],
          hlsSource: item?.media_url,
        };
        dispatch(addToQueue(d));
      });
    } else {
      const genre = data?.album?.genres?.map(
        (i, index) =>
          `${i?.title}${index !== data?.album?.genres?.length - 1 ? ", " : ""}`
      );
      const d = {
        ...data,
        subTitle: data?.album?.title,
        thumbnail: data?.album?.cover_image_url,
        footerText: genre[0],
        hlsSource: data?.media_url,
      };
      dispatch(addToQueue(d));
    }
  }

  const StreamMusic = async (id) => {
    try {
      const res = await axios.get(`${SERVER_URL.STREAM_MUSIC}${id}`, {
        headers: {
          Accept: "application/json",
        },
      });
    } catch (err) {}
  };

  const handlePlay = (data) => {
    ReactGA.event({
      category: "Play",
      action: `Play Song Clicked`,
      label: `${data?.title} is being played`,
    });
    ReactGA.event({
      category: "Play",
      action: `Play Song: ${data?.title}`,
      label: `${data?.title} is being played`,
    });
    setCurrentSong(data);
    const genre = data?.album?.genres?.map(
      (i, index) =>
        `${i?.title}${index !== data?.album?.genres?.length - 1 ? ", " : ""}`
    );
    const d = {
      isPlaying: true,
      musicArgs: {
        id: data?.id,
        title: data?.title,
        subTitle: data?.album?.title,
        hlsSource: data?.media_url,
        thumbnail: data?.album?.cover_image_url,
        footerText: genre[0],
        duration: data?.duration,
        artist_id: data?.album?.artists[0]?.id,
        isLiked: data?.is_liked,
      },
    };
    StreamMusic(data?.id);
    dispatch(setMusic(d));
  };

  return (
    <NavContainer>
      <MetaTags
        title={"TriniJungleJuice: Global Search"}
        url={window.location.href}
      />
      <div className={GlobalSearchCss.heading}>Search</div>
      <Grid container className={GlobalSearchCss.gridContainer}>
        <Grid item xs={10.5} sm={11} md={11.5}>
          <div className={GlobalSearchCss.SearchDiv}>
            <TextField
              placeholder="Search"
              onChange={(event) => setSearch(event.target.value)}
              fullWidth
              value={search}
              onKeyDown={handleKeyPress}
              sx={{
                "& .MuiInputBase-root": {
                  borderRadius: 6,
                  backgroundColor: "rgba(255,255,255,0.5)",
                },
                "& .MuiInputBase-root input": {
                  color: "#000",
                  paddingRight: "60px",
                  paddingLeft: "30px",
                  fontSize: 14,
                  fontFamily: "Inter-SemiBold",
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  display: "none",
                },
                "& .MuiOutlinedInput-root fieldset": {
                  display: "none",
                },
              }}
            />
            <IconButton
              styles={{
                right: 8,
                display: "flex",
                alignSelf: "center",
                backgroundColor: "transparent",
                position: "absolute",
              }}
              onClick={() => handleSearchResults()}
              icon={<Search style={{ color: "#fff", fontSize: 18 }} />}
            />
          </div>
        </Grid>
        <Grid
          item
          xs={1.5}
          sm={1}
          md={0.5}
          className={GlobalSearchCss.gridContainer2}
        >
          <img
            src={require("../../assets/images/filter.png")}
            className={GlobalSearchCss.filterSm}
            onClick={() => setOpen(true)}
          />
          <Drawer
            anchor={"right"}
            open={open}
            onClose={() => setOpen(false)}
            sx={{
              "& .MuiDrawer-paper": {
                backgroundColor: "#1a1a1a",
                width: width < 900 ? "100%" : "30%",
              },
            }}
          >
            <Filter
              onSearch={(val) => handleSearch(val)}
              value={value}
              onClose={() => setOpen(false)}
              onReset={() => handleReset()}
              tagOptions={tagsList}
              categoryOptions={categoryList}
              countryOptions={countryList}
              promoterOptions={allPromoters}
              photographerOptions={photographers}
              authorOptions={authorsList}
              artistOptions={artists}
              albumOptions={allAlbums}
              genreOptions={allGenres}
              yearOptions={years}
              regionOptions={regionList}
            />
          </Drawer>
        </Grid>
      </Grid>
      <div className={GlobalSearchCss.tabDiv}>
        <div className={GlobalSearchCss.leftShadow} />
        <StyledTabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons={width < 431 ? false : true}
          allowScrollButtonsMobile={width < 431 ? false : true}
          aria-label={
            width < 431
              ? "scrollable prevent tabs example"
              : "scrollable force tabs example"
          }
        >
          {tabs.map((item, index) => {
            return (
              <StyledTab
                key={index}
                label={item.label}
                {...a11yProps(item.index)}
              />
            );
          })}
        </StyledTabs>
        {searchingStarted ? (
          <>
            <TabPanel value={value} index={0}>
              {(newsArticles && newsArticles?.length !== 0) ||
              newsArticlesLoading ||
              (carnivals && carnivals?.length !== 0) ||
              carnivalsIsLoading ||
              (events && events?.length !== 0) ||
              eventsIsLoading ||
              (galleries && galleries?.length !== 0) ||
              galleriesIsLoading ||
              (videos && videos?.length !== 0) ||
              videosIsLoading ||
              (songsList && songsList?.length !== 0) ||
              songsIsLoading ||
              (artistNameLists && artistNameLists?.length !== 0) ||
              artistsIsLoading ||
              (albums && albums?.length !== 0) ||
              albumsIsLoading ||
              (genresList && genresList?.length !== 0) ||
              genresIsLoading ||
              (reviews && reviews?.length !== 0) ||
              reviewsIsLoading ? (
                <All
                  setValue={(i) => {
                    window.scrollTo(0, 0);
                    handleChange(0, i);
                  }}
                  events={events}
                  eventsIsLoading={eventsIsLoading}
                  galleries={galleries}
                  galleriesIsLoading={galleriesIsLoading}
                  videos={videos}
                  videosIsLoading={videosIsLoading}
                  carnivals={carnivals}
                  carnivalsIsLoading={carnivalsIsLoading}
                  newsArticles={newsArticles}
                  newsArticlesLoading={newsArticlesLoading}
                  reviews={reviews}
                  reviewsIsLoading={reviewsIsLoading}
                  artists={artistNameLists}
                  artistsIsLoading={artistsIsLoading}
                  albums={albums}
                  albumsIsLoading={albumsIsLoading}
                  genres={genresList}
                  genresIsLoading={genresIsLoading}
                  songs={songsList}
                  songsIsLoading={songsIsLoading}
                  totalSongs={totalSongs}
                  totalGenres={totalGenres}
                  totalAlbums={totalAlbums}
                  totalArtists={totalArtists}
                  totalReviews={totalReviews}
                  totalCarnivals={totalCarnivals}
                  totalGalleries={totalGalleries}
                  totalVideos={totalVideos}
                  totalEvents={totalEvents}
                  totalNewsArticles={totalNewsArticles}
                  handleAddToQueue={handleAddToQueue}
                  handlePlay={handlePlay}
                  currentSong={currentSong}
                  handleDownload={handleDownload}
                />
              ) : (
                <NoData />
              )}
            </TabPanel>
            <TabPanel value={value} index={1}>
              {(newsArticles && newsArticles?.length !== 0) ||
              newsArticlesLoading ? (
                <News
                  isLoading={newsArticlesLoading}
                  news={newsArticles}
                  length={totalNewsArticles}
                  setValue={(i) => handleChange(0, i)}
                  moreNewsArticlesIsLoading={moreNewsArticlesIsLoading}
                  newsArticlesPageNumber={newsArticlesPageNumber}
                  newsArticlesLastPage={newsArticlesLastPage}
                  updateNewsArticlesPageNumber={updateNewsArticlesPageNumber}
                />
              ) : (
                <NoData />
              )}
            </TabPanel>
            <TabPanel value={value} index={2}>
              {(carnivals && carnivals?.length !== 0) || carnivalsIsLoading ? (
                <Carnival
                  isLoading={carnivalsIsLoading}
                  carnival={carnivals}
                  length={totalCarnivals}
                  setValue={(i) => handleChange(0, i)}
                  moreCarnivalsIsLoading={moreCarnivalsIsLoading}
                  carnivalsPageNumber={carnivalsPageNumber}
                  carnivalsLastPage={carnivalsLastPage}
                  updateCarnivalsPageNumber={updateCarnivalsPageNumber}
                />
              ) : (
                <NoData />
              )}
            </TabPanel>
            <TabPanel value={value} index={3}>
              {(events && events?.length !== 0) || eventsIsLoading ? (
                <Event
                  isLoading={eventsIsLoading}
                  events={events}
                  length={totalEvents}
                  setValue={(i) => handleChange(0, i)}
                  moreEventsIsLoading={moreEventsIsLoading}
                  eventsPageNumber={eventsPageNumber}
                  eventsLastPage={eventsLastPage}
                  loadMoreEvents={loadMoreEvents}
                />
              ) : (
                <NoData />
              )}
            </TabPanel>
            <TabPanel value={value} index={4}>
              {(galleries && galleries?.length !== 0) || galleriesIsLoading ? (
                <Gallery
                  isLoading={galleriesIsLoading}
                  galleries={galleries}
                  length={totalGalleries}
                  setValue={(i) => handleChange(0, i)}
                  moreGalleriesIsLoading={moreGalleriesIsLoading}
                  galleriesPageNumber={galleriesPageNumber}
                  galleriesLastPage={galleriesLastPage}
                  updateGalleryPageNumber={updateGalleryPageNumber}
                />
              ) : (
                <NoData />
              )}
            </TabPanel>
            <TabPanel value={value} index={5}>
              {(videos && videos?.length !== 0) || videosIsLoading ? (
                <Videos
                  isLoading={videosIsLoading}
                  videos={videos}
                  length={totalVideos}
                  setValue={(i) => handleChange(0, i)}
                  moreVideosIsLoading={moreVideosIsLoading}
                  videosPageNumber={videosPageNumber}
                  videosLastPage={videosLastPage}
                  updateVideoPageNumber={updateVideoPageNumber}
                />
              ) : (
                <NoData />
              )}
            </TabPanel>
            <TabPanel value={value} index={6}>
              {(songsList && songsList?.length !== 0) || songsIsLoading ? (
                <Song
                  isLoading={songsIsLoading}
                  song={songsList}
                  length={totalSongs}
                  setValue={(i) => handleChange(0, i)}
                  moreSongsIsLoading={moreSongsIsLoading}
                  songsPageNumber={songsPageNumber}
                  songsLastPage={songsLastPage}
                  loadMoreSongs={loadMoreSongs}
                  handleAddToQueue={handleAddToQueue}
                  handlePlay={handlePlay}
                  currentSong={currentSong}
                  handleDownload={handleDownload}
                />
              ) : (
                <NoData />
              )}
            </TabPanel>
            <TabPanel value={value} index={7}>
              {(artistNameLists && artistNameLists?.length !== 0) ||
              artistsIsLoading ? (
                <Artists
                  isLoading={artistsIsLoading}
                  artists={artistNameLists}
                  length={totalArtists}
                  setValue={(i) => handleChange(0, i)}
                  moreArtistIsLoading={moreArtistIsLoading}
                  artistsPageNumber={artistsPageNumber}
                  artistsLastPage={artistsLastPage}
                  updateArtistsPageNumber={updateArtistsPageNumber}
                />
              ) : (
                <NoData />
              )}
            </TabPanel>
            <TabPanel value={value} index={8}>
              {(albums && albums?.length !== 0) || albumsIsLoading ? (
                <Albums
                  isLoading={albumsIsLoading}
                  albums={albums}
                  length={totalAlbums}
                  setValue={(i) => handleChange(0, i)}
                  moreAlbumsIsLoading={moreAlbumsIsLoading}
                  albumsPageNumber={albumsPageNumber}
                  albumsLastPage={albumsLastPage}
                  loadMoreAlbums={loadMoreAlbums}
                />
              ) : (
                <NoData />
              )}
            </TabPanel>
            <TabPanel value={value} index={9}>
              {(genresList && genresList?.length !== 0) || genresIsLoading ? (
                <Genres
                  isLoading={genresIsLoading}
                  genres={genresList}
                  length={totalGenres}
                  setValue={(i) => handleChange(0, i)}
                  moreGenresIsLoading={moreGenresIsLoading}
                  genresPageNumber={genresPageNumber}
                  genresLastPage={genresLastPage}
                  loadMoreGenres={loadMoreGenres}
                />
              ) : (
                <NoData />
              )}
            </TabPanel>
            <TabPanel value={value} index={10}>
              {(reviews && reviews?.length !== 0) || reviewsIsLoading ? (
                <Reviews
                  isLoading={reviewsIsLoading}
                  reviews={reviews}
                  length={totalReviews}
                  setValue={(i) => handleChange(0, i)}
                  moreReviewsIsLoading={moreReviewsIsLoading}
                  ReviewsPageNumber={ReviewsPageNumber}
                  ReviewsLastPage={ReviewsLastPage}
                  updateReviewsPageNumber={updateReviewsPageNumber}
                />
              ) : (
                <NoData />
              )}
            </TabPanel>
          </>
        ) : (
          <NoData
            text={"Start Searching"}
            image={require("../../assets/images/start_searching.png")}
          />
        )}

        <div className={GlobalSearchCss.rightShadow} />
      </div>
    </NavContainer>
  );
}

function NoData({ image, text }) {
  return (
    <div className={GlobalSearchCss.noDataFound}>
      <img
        src={image ? image : require("../../assets/images/search.png")}
        className={GlobalSearchCss.noDataImg}
      />
      {text ? (
        <div>{text}</div>
      ) : (
        <div>Uhh Uh! Nothing in search results. Try again.</div>
      )}
    </div>
  );
}
