import React, { useContext, useEffect, useState } from "react";
import { FaLocationArrow } from "react-icons/fa6";
import { GiCarnivalMask } from "react-icons/gi";
import NavContainer from "../../elements/NavContainer/NavContainer";
import { Grid, Modal, Skeleton } from "@mui/material";
import Label from "../../elements/label/Label";
import EventCss from "./Events.module.css";
import Chip from "../../components/chip/Chip";
import SharingTooltip from "../../components/sharing-tooltip/SharingTooltip";
import Button from "../../components/button/Button";
import { Button as MUIButton } from "@mui/material";
import { FaCalendarAlt, FaPlus } from "react-icons/fa";
import CountryFooter from "../../elements/country-footer/CountryFooter";
import GalleryList from "../../elements/Flatlist/GalleryList";
import VideoPlayer from "../../components/video-player/VideoPlayer";
import RatingComp from "./elements/RatingComp";
import { LocationOn } from "@mui/icons-material";
import { IoPersonSharp } from "react-icons/io5";
import MetaTags from "../../MetaTags";
import GroupsIcon from "@mui/icons-material/Groups";
import EventSlides from "../../elements/event-slides/EventSlides";
import Itinerary from "../../elements/itinerary/Itinerary";
import RatingBox from "../../components/rating-box/RatingBox";
import { Context } from "../../Context";
import { setEventLocation } from "../../slices/EventReducer";
import { connect, useDispatch } from "react-redux";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { TbCategoryFilled } from "react-icons/tb";
import RatingModal from "../../components/rating-modal/RatingModal";
import Swal from "sweetalert2";
import DateRangeIcon from "@mui/icons-material/DateRange";
import EventCardLoader from "../../elements/skeletonLoaders/EventCardLoader";
import ViewMore from "../../elements/view-more/ViewMore";
import CalendarTooltip from "../../components/calendar-tooltip/CalendarTooltip";
import { LocationExtractor } from "../../elements/location-extractor/LocationExtractor";
import {
  CalendarDateExtractor,
  ExtractDate,
  GetTomorrowDate,
} from "../../elements/date-extractor/ExtractDate";
import {
  EventTimeExtractor,
  TimeExtractor,
} from "../../elements/time-extractor/TimeExtractor";
import VideoCardLoader from "../../elements/skeletonLoaders/VideoCardLoader";
import VideoCard from "../../components/video-card/VideoCard";
import { PER_PAGE_COUNT, SERVER_URL } from "../../Constants.ts";
import axios from "axios";
import NoDataScreen from "../no-data/NoDataScreen.js";
import GalleryCardLoader from "../../elements/skeletonLoaders/GalleryCardLoader.js";
import NewsCardLoader from "../../elements/skeletonLoaders/NewsCardLoader.js";
import NewsList from "../../elements/Flatlist/NewsList.js";
import ReactGA from "react-ga4";

const EventDetail = ({ isLoggedIn, user }) => {
  const { slug } = useParams();
  const [width, setWidth] = useState(window.innerWidth);
  const [openItinerary, setOpenItinerary] = useState(false);
  const { setPageTitle } = useContext(Context);
  const [isLoading, setIsLoading] = useState(true);
  const [galleriesIsLoading, setGalleriesIsLoading] = useState(true);
  const dispatch = useDispatch();
  let [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();
  const [galleries, setGalleries] = useState([]);
  const [itineraries, setItineraries] = useState([]);
  const [featuredEvents, setFeaturedEvents] = useState(null);
  const [upcomingEvents, setUpcomingEvents] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [detail, setDetail] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [isSubscribing, setIsSubscribing] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const [videoList, setVideoList] = useState(null);
  const navigate = useNavigate();
  const [slugUrl, setSlugUrl] = useState(null);
  const [videosIsLoading, setVideosIsLoading] = useState(true);
  const [past, setPast] = useState(false);
  const [detailsIsLoading, setDetailsIsLoading] = useState(true);
  const [url, setUrl] = useState(null);
  const [featuredEventsIsLoading, setFeaturedEventsIsLoading] = useState(true);
  const [upcomingEventsIsLoading, setUpcomingEventsIsLoading] = useState(true);
  const [itinerariesIsLoading, setItinerariesIsLoading] = useState(true);
  const [ratings, setRatings] = useState(null);
  const [status, setStatus] = useState(null);
  const [reviews, setReviews] = useState(null);
  const [reviewsIsLoading, setReviewsIsLoading] = useState(null);
  const [ReviewsPageNumber, setReviewsPageNumber] = useState(1);
  const [ReviewsLastPage, setReviewsLastPage] = useState(null);
  const [moreReviewsIsLoading, setMoreReviewsIsLoading] = useState(false);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/events/${slug}`,
      title: "Event Detail",
    });
  }, []);

  useEffect(() => {
    if (pathname) {
      const u = pathname?.split("/")[2];
      setSlugUrl(`${SERVER_URL.GET_EVENTS_DETAILS}${u}`);
    }
  }, [pathname]);

  useEffect(() => {
    if (slugUrl) {
      handleGetEventDetails();
    }
  }, [slugUrl]);

  useEffect(() => {
    if (detail) {
      setUrl(`${SERVER_URL.GET_EVENTS_DETAILS_BY_ID}${detail?.id}`);
    }
  }, [detail]);

  useEffect(() => {
    if (url) {
      handleGetEventVideos();
      getFeaturedEvents();
      getUpcomingEvents();
      getEventGalleries();
      handleGetEventRatings();
      getEventReviews();
    }
  }, [url]);

  useEffect(() => {
    if (isLoggedIn) {
      handleGetItineraries();
    }
  }, [isLoggedIn]);

  const handleGetEventDetails = async () => {
    try {
      const res = await axios.get(slugUrl, {
        headers: {
          Accept: "application/json",
          ...(user?.token && { Authorization: `Bearer ${user.token}` }),
        },
      });
      setStatus(res.status);
      if (res.status === 200) {
        setDetail(res.data);
        setIsSubscribed(res?.data?.subscribed);
        setIsRegistered(res?.data?.registered);
        setDetailsIsLoading(false);
      }
    } catch (err) {
      setStatus(err.response.status);
      setDetailsIsLoading(false);
    }
  };

  const handleGetItineraries = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ITINERARIES, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      if (res.status === 200) {
        setItineraries(res?.data?.itineraries?.data);
        setItinerariesIsLoading(false);
      }
    } catch (err) {
      setItinerariesIsLoading(false);
    }
  };

  const handleCreateItineraries = async (body) => {
    try {
      const res = await axios.post(SERVER_URL.CREATE_ITINERARIES, body, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      if (res.status === 200) {
        handleGetItineraries();
        ReactGA.event({
          category: "Itinerary",
          action: "New itinerary created",
        });
        Swal.fire({
          title: "Itinerary created successfully",
          icon: "success",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } catch (err) {
      Swal.fire({
        title: "Unable to create the itinerary",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  const handleAddToItinerary = async (id) => {
    const body = {
      itinerary_id: id,
      event_id: detail?.id,
    };
    try {
      const res = await axios.post(SERVER_URL.ADD_TO_ITINERARIES, body, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      if (res.status === 200) {
        Swal.fire({
          title: res?.data?.message,
          icon: "success",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        });
        setOpenItinerary(false);
      }
    } catch (err) {
      Swal.fire({
        title: "Unable to add to the itinerary",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  const handleGetEventVideos = async () => {
    try {
      const res = await axios.get(url + "/videos", {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.EVENT_VIDEOS,
        },
      });
      if (res.status === 200) {
        if (res?.data && res?.data?.data) {
          setVideoUrl(res?.data?.data[0]?.media_url);
          setVideoList(res?.data?.data?.slice(1, 5));
        }
        setVideosIsLoading(false);
      }
    } catch (err) {
      setVideosIsLoading(false);
    }
  };

  const handleGetEventRatings = async () => {
    try {
      const res = await axios.get(url + "/ratings", {
        headers: {
          Accept: "application/json",
        },
      });
      if (res.status === 200) {
        setRatings(convertData(res?.data));
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  const handleSubmitRatings = async (value) => {
    const body = {
      crowd_score: value?.Crowd,
      vibe_score: value?.Vibe,
      venue_score: value?.Venue,
      food_score: value?.Food,
      service_score: value?.Service,
    };
    try {
      const res = await axios.post(url + `/rating`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${user?.token}`,
        },
      });
      if (res.status === 200) {
        handleGetEventRatings();
        ReactGA.event({
          category: "Rating's Submitted",
          action: `Submit your ratings button clicked`,
          label: detail?.title,
        });
        ReactGA.event({
          category: "Rating's Submitted",
          action: `Submit your ratings button clicked for ${detail?.title}`,
          label: detail?.title,
        });
        Swal.fire({
          title: "Ratings submitted successfully",
          icon: "success",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } catch (err) {
      Swal.fire({
        title: "Unable to submit your rating",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  const getUpcomingEvents = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_EVENTS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.EVENTS,
          type: "all",
          startDate: GetTomorrowDate(),
        },
      });
      if (res.status === 200) {
        if (res?.data?.data?.length !== 0) {
          setUpcomingEvents(res?.data?.data);
        }
        setUpcomingEventsIsLoading(false);
      }
    } catch (err) {
      setUpcomingEventsIsLoading(false);
    }
  };

  const getEventGalleries = async () => {
    try {
      // SERVER_URL.GET_EVENTS_GALLERIES
      const res = await axios.get(
        `${SERVER_URL.GET_EVENTS_DETAILS_BY_ID}${detail?.id}/related-galleries`,
        {
          headers: {
            Accept: "application/json",
          },
          params: {
            page: 1,
            items: PER_PAGE_COUNT.RELATED_EVENT_GALLERIES,
          },
        }
      );
      if (res.status === 200) {
        if (Array.isArray(res?.data) && res?.data?.length === 0) {
          setGalleries([]);
        } else {
          setGalleries(res?.data?.data);
        }
        setGalleriesIsLoading(false);
      }
    } catch (err) {
      setGalleriesIsLoading(false);
    }
  };

  const getEventReviews = async () => {
    try {
      const res = await axios.get(`${url}/reviews`, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: ReviewsPageNumber,
          items: PER_PAGE_COUNT.RELATED_EVENT_GALLERIES,
        },
      });
      if (res.status === 200) {
        if (res?.data) {
          setReviewsLastPage(res?.data?.last_page);
          if (ReviewsPageNumber === 1) {
            setReviews(res?.data?.data);
          } else {
            setReviews((prev) => [...prev, ...res?.data?.data]);
          }
        }
        setReviewsIsLoading(false);
        if (moreReviewsIsLoading) {
          setMoreReviewsIsLoading(false);
        }
      }
    } catch (err) {
      setReviewsIsLoading(false);
    }
  };

  useEffect(() => {
    if (ReviewsPageNumber !== 1) {
      getEventReviews();
    }
  }, [ReviewsPageNumber]);

  function updateReviewsPageNumber() {
    if (ReviewsLastPage != null && ReviewsPageNumber <= ReviewsLastPage) {
      setMoreReviewsIsLoading(true);
      setReviewsPageNumber(ReviewsPageNumber + 1);
    }
  }

  const getFeaturedEvents = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_EVENTS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.FEATURED_EVENTS,
          type: "featured",
        },
      });
      if (res.status === 200) {
        setFeaturedEvents(res?.data?.data);
        setFeaturedEventsIsLoading(false);
      }
    } catch (err) {
      setFeaturedEventsIsLoading(false);
    }
  };

  useEffect(() => {
    if (detail) {
      setPast(isDatePast(detail?.end_datetime));
      setPageTitle(detail?.title);
    } else {
      setPageTitle("Event Details");
    }
  }, [detail]);

  const handleNavigate = (param) => {
    const params = param?.tag
      ? `tag=${param.tag}`
      : param?.promoter
        ? `promoter=${param.promoter}`
        : `category=${param.category}`;
    const route = "/events?" + params;
    navigate(route);
    window.scrollTo({
      top: document.body.scrollHeight / 4,
      behavior: "smooth",
    });
  };

  const handleClick = (item) => {
    navigate(item);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleLocation = (item) => {
    const params = `region=${item}`;
    const route = "/events?" + params;
    navigate(route);
    window.scrollTo({
      top: document.body.scrollHeight / 4,
      behavior: "smooth",
    });
  };

  const handleSubscribe = async () => {
    setIsSubscribing(true);
    try {
      const res = await axios.get(url + `/subscribe`, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${user?.token}`,
        },
      });
      if (res.status === 200) {
        Swal.fire({
          title: res?.data?.message,
          icon: "success",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        });
        if (isSubscribed) {
          ReactGA.event({
            category: "Unsubscribe",
            action: `Unsubscribed an event`,
            label: detail?.title,
          });
          ReactGA.event({
            category: "Unsubscribe",
            action: `Unsubscribed am event ${detail?.title}`,
            label: detail?.title,
          });
        } else {
          ReactGA.event({
            category: "Subscribe",
            action: `Subscribed an event`,
            label: detail?.title,
          });
          ReactGA.event({
            category: "Subscribe",
            action: `Subscribed an event ${detail?.title}`,
            label: detail?.title,
          });
        }
        setIsSubscribed(!isSubscribed);
        setIsSubscribing(false);
      }
    } catch (err) {
      const msg = isSubscribed
        ? "Unable to unsubscribe the event"
        : "Unable to subscribe the event";
      Swal.fire({
        title: msg,
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
      setIsSubscribing(false);
    }
  };

  const handleRegister = async () => {
    setIsRegistering(true);
    try {
      const res = await axios.get(url + `/register`, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${user?.token}`,
        },
      });
      if (res.status === 200) {
        Swal.fire({
          title: res?.data?.message,
          icon: "success",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        });
        if (isRegistered) {
          ReactGA.event({
            category: "Unregister",
            action: `Unregister am event`,
            label: detail?.title,
          });
          ReactGA.event({
            category: "Unregister",
            action: `Unregister an event ${detail?.title}`,
            label: detail?.title,
          });
        } else {
          ReactGA.event({
            category: "Register",
            action: `Register an event`,
            label: detail?.title,
          });
          ReactGA.event({
            category: "Register",
            action: `Register an event ${detail?.title}`,
            label: detail?.title,
          });
        }
        setIsRegistered(!isRegistered);
        setIsRegistering(false);
      }
    } catch (err) {
      Swal.fire({
        title: "Unable to register/unregister the event",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
      setIsRegistering(false);
    }
  };

  function handleCalendarClick(label) {
    ReactGA.event({
      category: "Calendar",
      action: "Event added to calendar",
      label: label,
    });
    ReactGA.event({
      category: "Calendar",
      action: `${label} added to calendar`,
      label: label,
    });
  }

  const videoParams = {
    videoProps: {
      height: width < 431 ? 200 : 600,
      width: "100%",
      autoPlay: false,
      loop: false,
      controls: false,
      url: videoUrl,
    },
  };

  const RatingModalArgs = {
    onClick: (value) => handleSubmitRatings(value),
    args: ["Vibe", "Venue", "Crowd", "Food", "Service"],
  };

  const handleLogin = () => {
    Swal.fire({
      title: "Taking you to the login page.",
      width: 600,
      padding: "3em",
      color: "#fff",
      background: "#000",
      timer: 2000,
      showConfirmButton: false,
      imageUrl: require("../../assets/images/preview.gif"),
      imageWidth: 80,
      imageAlt: "Loading...",
    }).then(() => {
      navigate("/login");
      window.scrollTo(0, 0);
    });
  };

  function openLinkInNewTab(item) {
    let getLink = item;
    const aTag = document.createElement("a");
    aTag.setAttribute("href", getLink);
    aTag.setAttribute("target", "_blank");
    aTag.click();
    ReactGA.event({
      category: "Tickects",
      action: "Get Tickets button clicked",
      label: detail?.title,
    });
    ReactGA.event({
      category: "Tickects",
      action: `Get Tickets button clicked for ${detail?.title}`,
      label: detail?.title,
    });
  }

  function handleShare(label, item) {
    ReactGA.event({
      category: "Share",
      action: "Event Details's Share Button Clicked",
      label: label,
    });
    ReactGA.event({
      category: "Share",
      action: `${label} Shared on ${item} in event detail's page`,
      label: label,
    });
  }

  function handleOpen() {
    setOpenItinerary(true);
    ReactGA.event({
      category: "Itinerary",
      action: "Add to itinerary button clicked",
      label: detail?.title,
    });
    ReactGA.event({
      category: "Calendar",
      action: `${detail?.title} added to itinerary`,
      label: detail?.title,
    });
  }

  if (status && status !== 200) {
    return <NoDataScreen errorCode={status} />;
  }

  return (
    <NavContainer>
      <MetaTags
        author={"Trini Jungle Juice"}
        title={detail?.title}
        url={window.location.href}
        type={"event"}
        image={detail?.poster_url}
      />
      {detailsIsLoading ? (
        <Skeleton
          sx={{ bgcolor: "grey.900", marginBottom: 5 }}
          animation="wave"
          variant="rectangular"
          width={"30%"}
          height={30}
        />
      ) : (
        <Label size="lg" label={detail?.title} />
      )}
      <div className={EventCss.mgb}>
        <Grid container rowGap={2} columnGap={3}>
          <Grid item xs={12} md={5}>
            {detailsIsLoading ? (
              <Skeleton
                sx={{ bgcolor: "grey.900", marginBottom: 10 }}
                animation="wave"
                variant="rectangular"
                width={"100%"}
                height={400}
              />
            ) : (
              <img className={EventCss.poster} src={detail?.poster_url} />
            )}
          </Grid>
          <Grid item xs={12} md={6.7}>
            <div className={EventCss.showDate}>
              {detailsIsLoading ? (
                <Skeleton
                  sx={{ bgcolor: "grey.900", marginBottom: 0.5 }}
                  animation="wave"
                  variant="text"
                  width={"70%"}
                  height={30}
                />
              ) : (
                <>
                  <FaCalendarAlt className={EventCss.calendarIcon} />
                  <div className={EventCss.time}>
                    Start: {ExtractDate(detail?.start_datetime)},{" "}
                    {TimeExtractor(detail?.start_datetime)}
                  </div>
                  <div className={EventCss.time}>|</div>
                  <div className={EventCss.time}>
                    End: {ExtractDate(detail?.end_datetime)},{" "}
                    {TimeExtractor(detail?.end_datetime)}
                  </div>
                </>
              )}
            </div>
            {detailsIsLoading ? (
              Array.from({ length: 7 }, (_, index) => (
                <Skeleton
                  sx={{ bgcolor: "grey.900", marginBottom: 0.5 }}
                  animation="wave"
                  variant="text"
                  width={"100%"}
                  height={30}
                  key={index} // Add a unique key for each skeleton
                />
              ))
            ) : (
              <div
                className={`${EventCss.body} ck-content`}
                dangerouslySetInnerHTML={{ __html: detail?.description }}
              />
            )}
            <div className={EventCss.chipsArray}>
              {detailsIsLoading
                ? Array.from({ length: 6 }, (_, index) => (
                    <Skeleton
                      sx={{ bgcolor: "grey.900", marginBottom: 0.5 }}
                      animation="wave"
                      variant="text"
                      width={40}
                      height={50}
                      key={index} // Add a unique key for each skeleton
                    />
                  ))
                : detail?.tags?.map((item, index) => (
                    <Chip
                      handleClick={() => handleNavigate({ tag: item.slug })}
                      label={item?.slug}
                      key={index}
                    />
                  ))}
            </div>

            {detailsIsLoading ? (
              <div className={EventCss.buttonDiv}>
                <Skeleton
                  sx={{ bgcolor: "grey.900", marginBottom: 0.5 }}
                  animation="wave"
                  variant="circular"
                  width={50}
                  height={50}
                />
                {Array.from({ length: 4 }, (item, index) => (
                  <Skeleton
                    sx={{ bgcolor: "grey.900", marginBottom: 0.5 }}
                    animation="wave"
                    variant="rectangular"
                    width={100}
                    height={40}
                    key={index} // Add a unique key for each skeleton
                  />
                ))}
              </div>
            ) : (
              <div className={EventCss.buttonDiv}>
                <div className={EventCss.flexButton}>
                  <SharingTooltip
                    title={`Checkout this amazing ${detail?.title} `}
                    link={window.location.href}
                    onShare={handleShare}
                  />
                  <MUIButton
                    className={EventCss.customButton}
                    size={"medium"}
                    onClick={() => (isLoggedIn ? handleOpen() : handleLogin())}
                  >
                    <FaPlus className={EventCss.plus} /> Add to itinerary
                  </MUIButton>
                </div>
                {!past && detail?.registration_and_ticketing_allowed && (
                  <>
                    {detail?.is_registration_required && (
                      <Button
                        size="medium"
                        styles={{ width: width < 834 ? "100%" : "24%" }}
                        label={isRegistered ? "Registered" : "Register"}
                        onClick={() =>
                          isLoggedIn ? handleRegister() : handleLogin()
                        }
                        loading={isRegistering}
                        loadingLabel={"Registering"}
                      />
                    )}
                    {detail?.registration_url && (
                      <Button
                        size="medium"
                        styles={{ width: width < 834 ? "100%" : "24%" }}
                        label="Get Tickets"
                        onClick={() => {
                          openLinkInNewTab(detail?.registration_url);
                        }}
                      />
                    )}
                  </>
                )}
                <Button
                  size={"medium"}
                  styles={{ width: width < 834 ? "100%" : "24%" }}
                  label={isSubscribed ? "Unsubscribe" : "Subscribe"}
                  onClick={() =>
                    isLoggedIn ? handleSubscribe() : handleLogin()
                  }
                  loading={isSubscribing}
                  loadingLabel={"Subscribing"}
                />
              </div>
            )}
          </Grid>
        </Grid>
      </div>
      {openItinerary && (
        <Modal open={openItinerary} onClose={() => setOpenItinerary(false)}>
          {itinerariesIsLoading ? (
            <Skeleton
              sx={{
                bgcolor: "grey.900",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
              animation="wave"
              variant="rounded"
              width={400}
              height={300}
            />
          ) : (
            <Itinerary
              itinerary={itineraries}
              onClick={handleAddToItinerary}
              onCreate={handleCreateItineraries}
            />
          )}
        </Modal>
      )}
      <Grid container className={EventCss.contentDiv}>
        <Grid item xs={12} md={4} className={EventCss.gridGeneral}>
          {detailsIsLoading ? (
            <>
              <Skeleton
                sx={{ bgcolor: "grey.900", marginBottom: 1 }}
                animation="wave"
                variant="rectangular"
                width={"100%"}
                height={200}
              />
              <Skeleton
                sx={{ bgcolor: "grey.900", marginBottom: 0.5 }}
                animation="wave"
                variant="text"
                width={"70%"}
                height={30}
              />{" "}
              <Skeleton
                sx={{ bgcolor: "grey.900", marginBottom: 0.5 }}
                animation="wave"
                variant="text"
                width={"70%"}
                height={30}
              />
              <Skeleton
                sx={{ bgcolor: "grey.900", marginBottom: 0.5 }}
                animation="wave"
                variant="text"
                width={"70%"}
                height={30}
              />
            </>
          ) : (
            <>
              {" "}
              <div className={EventCss.flexDR}>
                <div
                  onClick={() =>
                    window.open(detail?.location?.google_map_url, "_blank")
                  }
                  className={EventCss.headingStyle}
                >
                  <LocationOn className={EventCss.locationIcon} />
                  Venue
                </div>
                {detail?.location && detail?.location?.google_map_embed_url && (
                  <iframe
                    title="location-map"
                    src={detail?.location?.google_map_embed_url}
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    className={EventCss.imgIcon}
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                )}
              </div>
              <div className={EventCss.contentStyle}>
                {detail?.location ? (
                  <>
                    {LocationExtractor(detail?.location)}
                    {detail?.location?.google_map_url && (
                      <div
                        className={EventCss.highlightedText}
                        onClick={() => {
                          ReactGA.event({
                            category: "Location",
                            action: "Opened in google maps",
                            label: detail?.title,
                          });
                          ReactGA.event({
                            category: "Location",
                            action: `${detail?.title} opened in google maps`,
                            label: detail?.title,
                          });
                          window.open(
                            detail?.location?.google_map_url,
                            "_blank"
                          );
                        }}
                      >
                        <FaLocationArrow />
                        Open in Google Maps
                      </div>
                    )}
                  </>
                ) : (
                  <div className={EventCss.highlightedText}>
                    No Location Found
                  </div>
                )}
              </div>
            </>
          )}
        </Grid>
        <Grid item xs={12} md={5} className={EventCss.spacingLR}>
          {detailsIsLoading ? (
            <>
              <div className={EventCss.headingStyle}>
                <Skeleton
                  sx={{ bgcolor: "grey.900" }}
                  animation="wave"
                  variant="text"
                  width={"40%"}
                  height={30}
                />{" "}
              </div>
              <div className={EventCss.contentStyle}>
                <Skeleton
                  sx={{ bgcolor: "grey.900" }}
                  animation="wave"
                  variant="text"
                  width={"70%"}
                  height={30}
                />
                <Skeleton
                  sx={{ bgcolor: "grey.900" }}
                  animation="wave"
                  variant="text"
                  width={"70%"}
                  height={30}
                />
              </div>
              <div className={EventCss.headingStyle}>
                <Skeleton
                  sx={{ bgcolor: "grey.900" }}
                  animation="wave"
                  variant="text"
                  width={"40%"}
                  height={30}
                />{" "}
              </div>
              <div className={EventCss.contentStyle}>
                <Skeleton
                  sx={{ bgcolor: "grey.900" }}
                  animation="wave"
                  variant="text"
                  width={"70%"}
                  height={30}
                />
                <Skeleton
                  sx={{ bgcolor: "grey.900" }}
                  animation="wave"
                  variant="text"
                  width={"70%"}
                  height={30}
                />
              </div>
            </>
          ) : (
            <>
              <div className={EventCss.flexDR}>
                <div className={EventCss.headingStyle}>
                  <DateRangeIcon className={EventCss.timeFontIcon} />
                  Date/Time
                </div>
                {!detail?.is_whole_day_event ? (
                  <div className={EventCss.contentStyle}>
                    <div>
                      Start: {ExtractDate(detail?.start_datetime)},{" "}
                      {TimeExtractor(detail?.start_datetime)}
                    </div>
                    <div>
                      End: {ExtractDate(detail?.end_datetime)},{" "}
                      {TimeExtractor(detail?.end_datetime)}
                    </div>
                  </div>
                ) : (
                  <div className={EventCss.contentStyle}>
                    <div>
                      {ExtractDate(detail?.start_datetime)}
                      {" (Whole Day Event)"}
                    </div>
                  </div>
                )}
              </div>

              {detail?.carnival && (
                <div className={EventCss.flexDR}>
                  <div className={EventCss.headingStyle}>
                    <GiCarnivalMask className={EventCss.timeFontIcon} />
                    Carnival
                  </div>
                  <div className={EventCss.contentStyle}>
                    <div
                      className={EventCss.highlightedText}
                      onClick={() =>
                        handleClick(`/carnival/${detail?.carnival?.slug}`)
                      }
                    >
                      {detail?.carnival?.title}
                    </div>
                  </div>
                </div>
              )}
              {detail?.event_categories?.length !== 0 && (
                <div className={EventCss.flexDR}>
                  <div className={EventCss.headingStyle}>
                    <TbCategoryFilled className={EventCss.locationIcon} />
                    Categories
                  </div>
                  <div className={EventCss.contentStyleC}>
                    {detail?.event_categories?.map((item, index) => (
                      <span
                        className={EventCss.highlightedText}
                        onClick={() => handleNavigate({ category: item?.slug })}
                        key={index}
                      >
                        {item?.title}
                        {index < detail?.event_categories?.length - 1
                          ? ", "
                          : ""}
                      </span>
                    ))}
                  </div>
                </div>
              )}
              {detail?.promoters?.length !== 0 && (
                <div className={EventCss.flexDR}>
                  <div className={EventCss.headingStyle}>
                    <IoPersonSharp className={EventCss.locationIcon} />
                    Contact
                  </div>
                  {detail?.promoters?.map((item, index) => {
                    return (
                      <div key={index} className={EventCss.contentStyle}>
                        <div
                          className={EventCss.highlightedText}
                          onClick={() =>
                            handleNavigate({
                              promoter: item?.slug,
                            })
                          }
                        >
                          Name: {item?.display_name}
                        </div>
                        {item?.is_contact_details_shown &&
                          item?.email !== "" && (
                            <div>Email: {item?.email} </div>
                          )}
                        {item?.is_contact_details_shown && item?.phone && (
                          <div>Phone: {item?.phone}</div>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </>
          )}
        </Grid>
        <Grid item xs={12} md={3} className={EventCss.gridGeneralL}>
          {detailsIsLoading ? (
            <>
              <div>
                <div className={EventCss.headingStyle}>
                  <Skeleton
                    sx={{ bgcolor: "grey.900" }}
                    animation="wave"
                    variant="text"
                    width={"40%"}
                    height={30}
                  />{" "}
                </div>
                <div className={EventCss.buttonDiv}>
                  {Array.from({ length: 5 }, (item, index) => (
                    <Skeleton
                      sx={{ bgcolor: "grey.900", marginBottom: 0.5 }}
                      animation="wave"
                      variant="circular"
                      width={40}
                      height={40}
                      key={index} // Add a unique key for each skeleton
                    />
                  ))}
                </div>
              </div>
              <div>
                <Skeleton
                  sx={{ bgcolor: "grey.900" }}
                  animation="wave"
                  variant="text"
                  width={"60%"}
                  height={30}
                />{" "}
                <Skeleton
                  sx={{ bgcolor: "grey.900" }}
                  animation="wave"
                  variant="text"
                  width={"40%"}
                  height={30}
                />{" "}
                <Skeleton
                  sx={{ bgcolor: "grey.900" }}
                  animation="wave"
                  variant="text"
                  width={"40%"}
                  height={30}
                />{" "}
              </div>
            </>
          ) : (
            <>
              <div className={EventCss.flexDR}>
                <div className={EventCss.headingStyleMgb}>
                  <FaCalendarAlt className={EventCss.timeFontIcon} />
                  Save to your Calendar
                </div>
                <CalendarTooltip
                  title={detail?.title}
                  onCalendarClick={handleCalendarClick}
                  location={LocationExtractor(detail?.location)}
                  timezone={detail?.location?.timezone}
                  description={detail?.description}
                  startDate={CalendarDateExtractor(detail?.start_datetime)}
                  startTime={EventTimeExtractor(detail?.start_datetime)}
                  endDate={CalendarDateExtractor(detail?.end_datetime)}
                  endTime={EventTimeExtractor(detail?.end_datetime)}
                  showTooltip={false}
                />
              </div>
              {!past &&
                detail?.registration_and_ticketing_allowed &&
                detail?.is_registration_required && (
                  <div className={EventCss.flexDR}>
                    <div className={EventCss.headingStyle}>
                      <GroupsIcon className={EventCss.timeFontIcon} />
                      Slots & Registration
                    </div>
                    <div className={EventCss.contentStyle}>
                      <div>
                        Capacity:{" "}
                        {detail?.capacity
                          ? detail?.capacity
                          : "No Attendance Limits"}
                      </div>
                      {detail?.capacity !== 0 && (
                        <div>
                          Currently Registered :{" "}
                          {detail?.registered_users_count ?? 0}
                        </div>
                      )}
                    </div>
                  </div>
                )}
            </>
          )}
        </Grid>
      </Grid>
      {past === true && (
        <>
          <div className={EventCss.ratingSubDiv}>
            <Label label={"Scores"} size="md" />
            <RatingModal {...RatingModalArgs} />
          </div>
          <div className={EventCss.mgb}>
            <Grid container columnGap={3}>
              <Grid item xs={12} sm={12} md={8}>
                {isLoading ? (
                  <Skeleton
                    sx={{ bgcolor: "grey.900", marginBottom: 10 }}
                    animation="wave"
                    variant="rectangular"
                    width={"100%"}
                    height={200}
                  />
                ) : (
                  <RatingBox
                    tjjRatingPercentage={((detail?.tjj_rating ?? 0) / 5) * 100}
                    userRatingPercentage={
                      ((detail?.user_rating ?? 0) / 5) * 100
                    }
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={3.4}>
                {isLoading ? (
                  <Skeleton
                    sx={{ bgcolor: "grey.900", marginBottom: 10 }}
                    animation="wave"
                    variant="rectangular"
                    width={"100%"}
                    height={200}
                  />
                ) : (
                  <RatingComp ratings={ratings} />
                )}
              </Grid>
            </Grid>
          </div>
        </>
      )}
      {past === true && (
        <>
          {reviewsIsLoading ? (
            <Skeleton
              sx={{ bgcolor: "grey.900", margin: "20px" }}
              variant="text"
              width={100}
              height={40}
            />
          ) : (
            reviews &&
            reviews?.length !== 0 && (
              <Label label={"Related Reviews"} size="lg" />
            )
          )}
          {reviewsIsLoading ? (
            <div className={EventCss.mgb}>
              <div className={EventCss.flexDrR}>
                {Array.from({ length: 4 }, (_, index) => {
                  return <NewsCardLoader key={index} />;
                })}
              </div>
            </div>
          ) : (
            reviews &&
            reviews?.length !== 0 && (
              <>
                <NewsList news={reviews} share={true} />
                {moreReviewsIsLoading && (
                  <div className={EventCss.mgt}>
                    <div className={EventCss.flexDrR}>
                      {Array.from({ length: 4 }, (_, index) => {
                        return <NewsCardLoader key={index} />;
                      })}
                    </div>
                  </div>
                )}
                {reviews &&
                reviews?.length !== 0 &&
                ReviewsLastPage !== null &&
                ReviewsPageNumber < ReviewsLastPage &&
                !reviewsIsLoading &&
                !moreReviewsIsLoading ? (
                  <ViewMore loadMore onClick={updateReviewsPageNumber} />
                ) : (
                  <div className={EventCss.mgb} />
                )}
              </>
            )
          )}
        </>
      )}
      {(videoUrl || (videoList && videoList?.length !== 0)) && (
        <Label label={"Videos"} size="md" />
      )}
      {(videosIsLoading || videoUrl) && (
        <div className={EventCss.mgb}>
          {videosIsLoading ? (
            <Skeleton
              sx={{ bgcolor: "grey.900" }}
              animation="wave"
              variant="rectangular"
              width={"100%"}
              height={600}
            />
          ) : (
            videoUrl && <VideoPlayer {...videoParams} />
          )}
        </div>
      )}
      {videosIsLoading ? (
        <>
          <div className={EventCss.flexDrR}>
            {Array.from({ length: 4 }, (_, index) => {
              return <VideoCardLoader key={index} size="sm" />;
            })}
          </div>
          <div className={EventCss.mgb} />
        </>
      ) : (
        videoList &&
        videoList?.length !== 0 && (
          <>
            <VideoList
              size={"sm"}
              videos={videoList}
              handleClick={handleClick}
            />
            <ViewMore onClick={() => handleClick("/videos/all")} />
          </>
        )
      )}
      {(galleriesIsLoading || galleries?.length !== 0) && (
        <Label label={"Related Galleries"} size="md" />
      )}
      {galleriesIsLoading ? (
        <div className={EventCss.mgb}>
          <div className={EventCss.flexDrR}>
            {Array.from({ length: 8 }, (_, index) => {
              return <GalleryCardLoader key={index} />;
            })}
          </div>
        </div>
      ) : (
        galleries &&
        galleries?.length !== 0 && (
          <>
            <GalleryList gallery={galleries} />
            {galleries?.length > PER_PAGE_COUNT.RELATED_EVENT_GALLERIES ? (
              <ViewMore onClick={() => handleClick("/gallery/all")} />
            ) : (
              <div className={EventCss.mgb} />
            )}
          </>
        )
      )}
      {(featuredEventsIsLoading ||
        (featuredEvents && featuredEvents?.length !== 0)) && (
        <Label label={"Featured Events"} size="md" />
      )}
      {featuredEventsIsLoading ? (
        <div className={EventCss.flexDrR}>
          {Array.from({ length: 4 }, (_, index) => {
            return <EventCardLoader key={index} />;
          })}
        </div>
      ) : (
        featuredEvents &&
        featuredEvents?.length !== 0 && (
          <>
            <EventSlides data={featuredEvents} />
          </>
        )
      )}
      {(featuredEventsIsLoading ||
        (featuredEvents && featuredEvents?.length !== 0)) && (
        <div className={EventCss.mgb} />
      )}

      {(upcomingEventsIsLoading ||
        (upcomingEvents && upcomingEvents?.length !== 0)) && (
        <Label label={"Upcoming Events"} size="md" />
      )}
      {upcomingEventsIsLoading ? (
        <div className={EventCss.flexDrR}>
          {Array.from({ length: 4 }, (_, index) => {
            return <EventCardLoader key={index} />;
          })}
        </div>
      ) : (
        upcomingEvents &&
        upcomingEvents?.length !== 0 && (
          <>
            <EventSlides data={upcomingEvents} />
          </>
        )
      )}
      {(upcomingEventsIsLoading ||
        (upcomingEvents && upcomingEvents?.length !== 0)) && (
        <div className={EventCss.mgb} />
      )}

      <CountryFooter
        by={"Events"}
        handleClick={(item) => handleLocation(item)}
      />
    </NavContainer>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn, // Assuming `isLoggedIn` is stored in your Redux state
  user: state?.auth?.user,
});

export default connect(mapStateToProps)(EventDetail);

function isDatePast(date1String) {
  const date1 = new Date(date1String);
  const date2 = new Date();

  if (isNaN(date1.getTime()) || isNaN(date2.getTime())) {
    return "Invalid date format";
  }

  if (date1 <= date2) {
    return true;
  } else {
    return 0;
  }
}

function VideoList({ videos, size, handleClick }) {
  return (
    <div className={EventCss.flexDrR}>
      {videos?.map((item, index) => {
        const date = ExtractDate(item.created_at);
        return (
          <VideoCard
            key={index}
            title={item?.title}
            viewCount={item?.watch_count}
            likeCount={item?.like_count}
            subTitle={date}
            thumbnailLink={item?.cover_image_url}
            onClick={() => handleClick(`/videos/${item?.slug}`)}
            orientation={"portrait"}
            size={size}
          />
        );
      })}
    </div>
  );
}

function convertData(data) {
  const aspects = ["vibe", "venue", "crowd", "food", "service"];

  const convertedData = aspects.map((aspect, index) => ({
    name: aspect,
    tjjRating:
      data?.tjj_rating &&
      Object.keys(data?.tjj_rating).length > 0 &&
      data?.tjj_rating[`${aspect.toLowerCase()}_score`]
        ? data?.tjj_rating[`${aspect.toLowerCase()}_score`]
        : 0,
    userRating:
      data?.user_rating &&
      Object.keys(data?.user_rating).length > 0 &&
      data?.user_rating[`${aspect.toLowerCase()}_score`]
        ? data?.user_rating[`${aspect.toLowerCase()}_score`]
        : 0,
  }));
  return convertedData;
}
