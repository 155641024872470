import React, { useContext, useEffect, useState } from "react";
import NavContainer from "../../elements/NavContainer/NavContainer";
import AccountCss from "./AccountsCss.module.css";
import EventList from "./elements/EventList";
import Label from "../../elements/label/Label";
import EventSlides from "../../elements/event-slides/EventSlides";
import Button from "../../components/button/Button";
import { Delete, Edit } from "@mui/icons-material";
import IconButton from "../../components/icon-button/IconButton";
import { Modal, Skeleton } from "@mui/material";
import { Context } from "../../Context";
import SharingTooltip from "../../components/sharing-tooltip/SharingTooltip";
import MetaTags from "../../MetaTags";
import { connect } from "react-redux";
import { PER_PAGE_COUNT, SERVER_URL } from "../../Constants.ts";
import axios from "axios";
import { useLoggedInState } from "../../elements/logged-in-state/useLoggedInState";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import EditItinerary from "./elements/EditItinerary.js";
import NoDataScreen from "../no-data/NoDataScreen.js";
import Unauthorized from "../no-data/Unauthorized.js";
import AddIcon from "@mui/icons-material/Add";
import EventLongCardLoader from "../../elements/skeletonLoaders/EventLongCardLoader.js";
import ReactGA from "react-ga4";

function ItineraryDetail({ user }) {
  const { slug } = useParams();
  const [width, setWidth] = useState(window.innerWidth);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [recommendedEvents, setRecommendedEvents] = useState(null);
  const { setPageTitle } = useContext(Context);
  const isLoggedIn = useLoggedInState();
  const { pathname } = useLocation();
  const [tempId, setTempId] = useState(null);
  const [slugUrl, setSlugUrl] = useState(null);
  const [details, setDetails] = useState(null);
  const [recommendedEventsIsLoading, setRecommendedEventsIsLoading] =
    useState(true);
  const [status, setStatus] = useState(null);
  const [isAuthorized, setIsAuthorized] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (tempId) {
      ReactGA.send({
        hitType: "pageview",
        page: `/my-itinerary/${tempId}/${slug}`,
        title: "Itinerary Detail",
      });
    }
  }, [tempId]);

  const handleClick = (item) => {
    navigate(item);
    window.scrollTo(0, 0);
  };

  const getRecommendedEvents = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_EVENTS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.EVENTS,
          type: "featured",
        },
      });
      if (res.status === 200) {
        if (res?.data && res?.data?.data) {
          setRecommendedEvents(res?.data?.data);
        }
        setRecommendedEventsIsLoading(false);
      }
    } catch (err) {
      setRecommendedEventsIsLoading(false);
    }
  };

  useEffect(() => {
    if (pathname) {
      const u = pathname?.split("/")[2];
      const url = pathname?.split("/")[3];
      const id = u?.split("-")[2];
      setTempId(id);
      setSlugUrl(SERVER_URL.GET_USER_ITINERARY_DETAIL + url);
    }
  }, [pathname]);

  useEffect(() => {
    if (slugUrl && isAuthorized) {
      handleGetDetails();
    }
  }, [slugUrl, isAuthorized]);

  useEffect(() => {
    if (tempId && user?.id) {
      const authorize = parseInt(tempId) === parseInt(user?.id);
      setIsAuthorized(authorize);
    }
  }, [tempId, user]);

  const handleGetDetails = async () => {
    try {
      const res = await axios.get(slugUrl, {
        headers: {
          Accept: "application/json",
        },
      });
      setStatus(res.status);
      if (res.status === 200) {
        if (res?.data && res?.data?.itinerary) {
          setDetails(res?.data?.itinerary);
        }
        setIsLoading(false);
      }
    } catch (err) {
      setStatus(err.response.status);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isAuthorized) {
      getRecommendedEvents();
    }
  }, [isAuthorized]);

  useEffect(() => {
    if (isAuthorized) {
      if (details) {
        setPageTitle(details?.title);
      } else {
        setPageTitle("Itinerary Details");
      }
    }
  }, [details, isAuthorized]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleEditItinerary = async (id, body) => {
    try {
      const res = await axios.post(
        `${SERVER_URL.EDIT_ITINERARIES}${id}/update`,
        body,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      if (res.status === 200) {
        ReactGA.event({
          category: "Itinerary",
          action: "Edit itinerary button clicked",
          label: "An itinerary is edited",
        });
        setIsLoading(true);
        handleGetDetails();
        Swal.fire({
          title: res?.data?.message,
          icon: "success",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } catch (err) {
      Swal.fire({
        title: "Unable to edit the itinerary",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  const handleDeleteItinerary = async (id) => {
    try {
      const res = await axios.get(
        `${SERVER_URL.EDIT_ITINERARIES}${id}/delete`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      if (res.status === 200) {
        ReactGA.event({
          category: "Itinerary",
          action: "Delete itinerary button clicked",
          label: "An itinerary is deleted",
        });
        setIsLoading(true);
        handleClick(`/my-itinerary/${user?.slug}`);
        Swal.fire({
          title: res?.data?.message,
          icon: "success",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } catch (err) {
      Swal.fire({
        title: "Unable to delete the itinerary",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  const handleDeleteFromItinerary = async (id, eventId) => {
    const body = {
      itinerary_id: id,
      event_id: eventId,
    };
    try {
      const res = await axios.post(SERVER_URL.ADD_TO_ITINERARIES, body, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      if (res.status === 200) {
        ReactGA.event({
          category: "Itinerary",
          action: "Delete event from itinerary button clicked",
          label: "An event has been deleted from an itinerary",
        });
        setIsLoading(true);
        handleGetDetails();
        Swal.fire({
          title: res?.data?.message,
          icon: "success",
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#000",
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } catch (err) {
      Swal.fire({
        title: "Unable to delete from the itinerary",
        icon: "error",
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#000",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  const handleAddMoreEvents = () => {
    navigate("/events");
    ReactGA.event({
      category: "Itinerary",
      action: "Add more events button clicked",
      label: "Add more events button clicked",
    });
    window.scrollTo(0, 0);
  };

  function handleShare(label, item) {
    ReactGA.event({
      category: "Share",
      action: "Itinerary's Share Button Clicked",
      label: label,
    });
    ReactGA.event({
      category: "Share",
      action: `${label} itinerary Shared on ${item}`,
      label: label,
    });
  }

  if (isAuthorized === false) {
    return <Unauthorized />;
  }

  if (status && status !== 200) {
    return <NoDataScreen errorCode={status}/>;
  }

  return (
    <NavContainer>
      <MetaTags
        author={"Trini Jungle Juice"}
        title={details?.title}
        url={window.location.href}
        type={"website"}
        image="../../assets/images/tjj-logo.jpg"
      />
      <div className={AccountCss.mainDiv}>
        <Label label={details?.title} size="lg" />
        {width < 500 ? (
          <div className={AccountCss.buttonDiv}>
            <SharingTooltip
            onShare={handleShare}
              transparent
              link={`https://${window.location.href?.split("/")[2]}/events/itinerary/${details?.slug}`}
            />
            <IconButton
              backgroundColor={"transparent"}
              onClick={() => setOpen(true)}
              icon={<Edit className={AccountCss.closeIcon} />}
            />
            <IconButton
              backgroundColor={"transparent"}
              icon={<Delete className={AccountCss.closeIcon} />}
              onClick={() => handleDeleteItinerary(details?.id)}
            />
            <IconButton
              backgroundColor={"transparent"}
              icon={<AddIcon className={AccountCss.closeIcon} />}
              onClick={() => handleAddMoreEvents()}
            />
          </div>
        ) : (
          <div className={AccountCss.buttonDiv}>
            <SharingTooltip
            onShare={handleShare}
              withText
              showAnimation={true}
              backgroundColor={"#1C1C1C"}
              hoverColor={"#ff0000"}
              link={`https://${window.location.href?.split("/")[2]}/events/itinerary/${details?.slug}`}
            />
            <Button
              label="Edit"
              showAnimation={true}
              backgroundColor={"#1C1C1C"}
              hoverColor={"#ff0000"}
              icon={<Edit className={AccountCss.closeIcon} />}
              iconLeft={true}
              onClick={() => setOpen(true)}
            />
            <Button
              label="Delete"
              showAnimation={true}
              backgroundColor={"#1C1C1C"}
              hoverColor={"#ff0000"}
              icon={<Delete className={AccountCss.closeIcon} />}
              iconLeft={true}
              onClick={() => handleDeleteItinerary(details?.id)}
            />
            <Button
              label="Add more events"
              showAnimation={true}
              backgroundColor={"#1C1C1C"}
              hoverColor={"#ff0000"}
              icon={<AddIcon className={AccountCss.closeIcon} />}
              iconLeft={true}
              onClick={() => handleAddMoreEvents()}
            />
          </div>
        )}

        {open && (
          <Modal
            open={open}
            onClose={() => setOpen(false)}
            className={AccountCss.modalDiv}
          >
            <EditItinerary
              onClick={(i) => handleEditItinerary(details?.id, i)}
              setOpen={(bool) => setOpen(bool)}
              name={details?.title}
              itineraryDescription={details?.description}
            />
          </Modal>
        )}
      </div>
      <div className={AccountCss.mainDiv}>
        <div className={AccountCss.description}>{details?.description}</div>
      </div>
      <div className={AccountCss.divMain}>
        {isLoading ? (
          <div className={AccountCss.mgb}>
            <Skeleton
              sx={{ bgcolor: "grey.900" }}
              animation="wave"
              variant="text"
              width={width < 600 ? "100%" : 566}
            />
            <div className={AccountCss.flexDrR}>
              {Array.from({ length: 4 }, (_, index) => (
                <div
                  style={{ width: width < 600 ? "100%" : "47%" }}
                  key={index}
                >
                  <EventLongCardLoader />
                </div>
              ))}
            </div>
          </div>
        ) : (
          <>
            {details?.events?.map((detail, index) => {
              return (
                <div key={index} className={AccountCss.mgb25}>
                  <div className={AccountCss.eventTag}>{detail?.timestamp}</div>
                  <EventList
                    handleOnRemove={(i) =>
                      handleDeleteFromItinerary(details?.id, i)
                    }
                    isLoggedIn={isLoggedIn}
                    events={detail?.events}
                    showButton={isLoggedIn && parseInt(tempId) === user?.id}
                  />
                </div>
              );
            })}
          </>
        )}
      </div>
      {recommendedEventsIsLoading ? (
        <Skeleton
          sx={{ bgcolor: "grey.900", marginBottom: 3 }}
          animation="wave"
          variant="text"
          width={width > 431 ? "30%" : "40%"}
          height={50}
        />
      ) : (
        recommendedEvents &&
        recommendedEvents?.length !== 0 && (
          <Label label={"Our Top Recommendations"} size="md" />
        )
      )}
      {recommendedEventsIsLoading ? (
        <div className={AccountCss.flexDrR}>
          {Array.from({ length: 4 }, () => {
            return (
              <Skeleton
                sx={{ bgcolor: "grey.900" }}
                animation="wave"
                variant="rectangular"
                width={273}
                height={400}
              />
            );
          })}
        </div>
      ) : (
        recommendedEvents &&
        recommendedEvents?.length !== 0 && (
          <>
            <EventSlides data={recommendedEvents} />
          </>
        )
      )}
    </NavContainer>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(ItineraryDetail);
